import { FC,useMemo } from 'react';
import {
    Grid,
    GridItem,
} from '@patternfly/react-core';
import { displayDashboardCards } from '../../constants/CardsStructure';
import { CompanyInfoCard } from './CompanyInfoCard';
import { DashboardCard } from './shared/DashboardCard';
import { IPartnerInfo,IPartnershipStatus } from '../../constants/apiconstants-pfs';
import { useQueryClient } from 'react-query';
import PartnerTrainingCard from './cards/PartnerTrainingCard';
import { HardwareCard } from './cards/HardwareCard';
import { CloudCard } from './cards/CloudCard';
import ProductsCard from './cards/ProductsCard/ProductsCard';
import { SupportCard } from './cards/SupportCard';

export const AuthenticatedDashBoard: FC = () => {
    const queryClient = useQueryClient();
    const partnerInformationDetails = queryClient.getQueryState(['partnerInfo'])
        ?.data as IPartnerInfo;
    const partnerInfoLoading = queryClient.getQueryState(['partnerInfo'])?.isFetching;
    const partnershipStatus = queryClient.getQueryState(['partnershipStatus'])
        ?.data as IPartnershipStatus;
    const partnershipStatusLoading = queryClient.getQueryState(['partnershipStatus'])?.isFetching;
    const dashboardCards = useMemo(
        () =>
            displayDashboardCards(
                partnerInformationDetails,
                partnerInfoLoading,
                partnershipStatus,
                partnershipStatusLoading,
            ),
        [partnerInfoLoading,partnerInformationDetails,partnershipStatus,partnershipStatusLoading],
    );
    return (
        <Grid hasGutter className={'authenticated-dashboard'}>
            <GridItem sm={12} md={12} lg={10}>
                <Grid hasGutter>
                    {
                        partnershipStatus?.techPartner &&
                        <ProductsCard />
                    }
                    {dashboardCards?.dashboardTopRowSection?.map((elm,index) => {
                        if (elm.visibility) {
                            return (
                                <GridItem
                                    className={partnershipStatus?.businessPartner ?
                                        "authenticated-dashboard__quarter-card-grid-item-with-sales-card":
                                        "authenticated-dashboard__quarter-card-grid-item"}
                                    sm={12} md={6} lg={3} key={index}>
                                    <DashboardCard
                                        link={elm.link}
                                        icon={elm.icon}
                                        title={elm.title}
                                        subtext={elm?.subtext}
                                        description={elm.description}
                                        openNewTab={elm.openNewTab}
                                        label={elm.label}
                                        isLoading={elm.isLoading}
                                        isSelectable={elm.isSelectable}
                                        mainSectionTitle={'Activities'}
                                        plainTextTitle={elm?.titleInPlainText}
                                    />
                                </GridItem>
                            );
                        }
                    })}
                    <HardwareCard />
                    <CloudCard />
                    <PartnerTrainingCard />
                    <SupportCard />
                    {dashboardCards?.dashboardBottomRowSection?.map((elm,index) => {
                        if (elm.visibility) {
                            return (
                                <GridItem
                                    className={partnershipStatus?.businessPartner ?
                                        "authenticated-dashboard__quarter-card-grid-item-with-sales-card":
                                        "authenticated-dashboard__quarter-card-grid-item"}
                                    sm={12} md={6} lg={3} key={index}>
                                    <DashboardCard
                                        link={elm.link}
                                        icon={elm.icon}
                                        title={elm.title}
                                        description={elm.description}
                                        openNewTab={elm.openNewTab}
                                        label={elm.label}
                                        isLoading={elm.isLoading}
                                        isSelectable={elm.isSelectable}
                                        mainSectionTitle={'Learning'}
                                        plainTextTitle={elm?.titleInPlainText}
                                    />
                                </GridItem>
                            );
                        }
                    })}
                </Grid>
            </GridItem>
            <GridItem sm={12} md={12} lg={2}>
                <CompanyInfoCard />
            </GridItem>
        </Grid>
    );
};
