import {
    Button,
    Card,
    CardBody,
    EmptyState,
    EmptyStateActions,
    EmptyStateFooter,
    EmptyStateHeader,
    EmptyStateIcon,
    TextContent,
    ToggleGroup,
    ToggleGroupItem,
    ToolbarGroup,
    ToolbarItem,
    Text,
    TextVariants,
    EmptyStateVariant,
    Bullseye,
    Spinner
} from '@patternfly/react-core';
import { PFTableV2,Table } from '../General/Table';
import { TableContextConsumer } from '../General/Table/TableContext';
import { TableToolbar } from '@rhc-shared-components/table-toolbar';
import { IColumn } from '../General/Table/PFTableV2';
import { useQuery } from 'react-query';
import { useState } from 'react';
import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
    InfoCircleIcon,
    WarningTriangleIcon,
} from '@patternfly/react-icons';
import './PartnerSubscriptions.scss';
import Env from '../../utils/env'
import { isEmpty } from 'lodash'
import { TableText } from '@patternfly/react-table'
import { getSubscriptionListQuery } from '../../api/superGraphQLAPI'
import {
    IGraphQLError,
    ISubscriptionInfoEdges,SkuProductsEntity,
} from '../../constants/apiconstants-supergraph'

export const PartnerSubscriptions = () => {
    const initialPaginationState = {
        page: 1,
        perPage: 10,
    };
    const [hasInteractedWithFilter , setInteractedWithFilter] = useState(false);
    const [isSelected,setIsSelected] = useState(
        'ACTIVE',
    );
    const [pagination] = useState(initialPaginationState);

    const {
        data: subscriptionList,
        isFetching: isPartnerSubsInfoFetching,
        error,
    } = useQuery<ISubscriptionInfoEdges[],IGraphQLError>(['subscriptionList',isSelected,pagination],
        async () => {
            const response = await getSubscriptionListQuery(pagination?.page,isSelected);
            return response?.data?.me?.subscriptionInfo?.subscriptions?.edges || [];
        });

    const isSubscriptionExpired = (data: SkuProductsEntity[]) => {
        return data?.some((elm) => elm?.status === 'EXPIRED');
    }
    const isExpiringSoon = (date: string,days: number) => {
        const differenceInDays = new Date(date).getTime() - new Date().getTime() / 1000 * 60 * 60 * 24;
        return differenceInDays > 0 && differenceInDays <= days; // Returns true if expiring within given days
    };

    const columns: IColumn[] = [
        {
            title: 'Subscription',
            key: 'subscription',
            sortable: false,
            cell: (data) => {
                return (
                    <TableText
                        className={isSubscriptionExpired(data?.node?.skuProducts) ? 'partner-subscriptions__disabled-row' : 'partner-subscriptions__row'}>
                        {isExpiringSoon(data?.node?.endDate,30) && <ExclamationTriangleIcon
                            className={'partner-subscriptions__warning-icon'} />}
                        {!isSubscriptionExpired(data?.node?.skuProducts) && <CheckCircleIcon
                            className={'partner-subscriptions__check-circle-icon'} />}
                        &nbsp;{data?.node?.name}
                    </TableText>
                )
            },
            accessor: (data) => `${data?.node?.name}`,
            extraProps: {
                column: { width: 40 },
                row: { className: 'pf-m-break-word' },
            },
        },
        {
            title: 'Quantity',
            sortable: false,
            key: 'quantity',
            cell: (data) => {
                return (
                    <TableText
                        className={isSubscriptionExpired(data?.node?.skuProducts) ? 'partner-subscriptions__disabled-row' : 'partner-subscriptions__row'}>{data?.node?.quantity}
                    </TableText>
                )

            },
            accessor: (data) => data?.node?.quantity,
            extraProps: {
                column: { width: 20 },
                row: { className: 'pf-m-break-word' },
            },
        },
        {
            title: 'Start date',
            sortable: false,
            key: 'startDate',
            cell: (data) => {
                return (
                    <TableText
                        className={isSubscriptionExpired(data?.node?.skuProducts) ? 'partner-subscriptions__disabled-row' : 'partner-subscriptions__row'}>{
                        new Date(data?.node?.createdDate).toLocaleDateString('en-US',{
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })
                    }</TableText>
                )
            },
            accessor: (data) =>
                new Date(data?.node?.createdDate).toLocaleDateString('en-US',{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                }),
            extraProps: { column: { width: 20 } },
        },
        {
            title: 'End date',
            sortable: false,
            key: 'endDate',
            cell: (data) => {
                return (
                    <TableText
                        className={isSubscriptionExpired(data?.node?.skuProducts) ? 'partner-subscriptions__disabled-row' : 'partner-subscriptions__row'}>{
                        new Date(data?.node?.endDate).toLocaleDateString('en-US',{
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })
                    }</TableText>
                )
            },
            accessor: (data) =>
                new Date(data?.node?.endDate).toLocaleDateString('en-US',{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                }),
            extraProps: { column: { width: 20 } },
        },
    ];

    const handleItemClick = (_event: any,_isSelected: boolean) => {
        const id = _event.currentTarget.id;
        setIsSelected(id);
        setInteractedWithFilter(true);
    };

    const SubscriptionPageHeader = () => {
        return (
            <Card className={'partner-subscriptions'}>
                <CardBody>
                    <TextContent>
                        <Text component={TextVariants.h1}>Partner Subscriptions Summary</Text>
                    </TextContent>
                </CardBody>
            </Card>
        )
    }

    if (isPartnerSubsInfoFetching && !hasInteractedWithFilter) {
        return (
            <>
                <SubscriptionPageHeader />
                <div
                    style={{
                        padding: '3%',
                    }}>
                    <Bullseye>
                        <Spinner />
                    </Bullseye>
                </div>
            </>
        )
    }

    if (!isEmpty(error)) {
        return (
            <Card>
                <CardBody>
                    <EmptyState variant={EmptyStateVariant.xs}>
                        <EmptyStateHeader icon={<EmptyStateIcon icon={() => {
                            return (<WarningTriangleIcon className={'partner-subscriptions__warning-icon'}/>)
                        }} />} />
                        <TextContent>
                            <Text component={TextVariants.h3}>Something went wrong</Text>
                        </TextContent>
                    </EmptyState>
                </CardBody>
            </Card>
        )
    }

    if (isEmpty(subscriptionList) && !isPartnerSubsInfoFetching && !hasInteractedWithFilter) {
        return (
            <>
                <SubscriptionPageHeader />
                <div
                    style={{
                        padding: '3%',
                    }}>
                    <Card>
                        <CardBody>
                            <EmptyState variant={EmptyStateVariant.xs}>
                                <EmptyStateHeader icon={<EmptyStateIcon icon={() => {
                                    return (<InfoCircleIcon
                                        className={'partner-subscriptions__info-icon'} />)
                                }} />} />
                                <TextContent>
                                    <Text component={TextVariants.h3}>You don&apos;t have any
                                        partner
                                        subscriptions</Text>
                                </TextContent>
                                <EmptyStateFooter>
                                    <EmptyStateActions>
                                        <Button variant="primary" component="a" target="_blank"
                                                href={`${Env.ConnectPortalUrls[Env.getEnvName()]}partner-apps/subscriptions`}>Request
                                            subscriptions</Button>
                                    </EmptyStateActions>
                                    <EmptyStateActions>
                                        <Button variant="link" component="a" target="_blank"
                                                href={`${Env.ConnectPortalUrls[Env.getEnvName()]}en/partner-with-us/partner-benefits/partner-subscriptions`}>Learn more</Button>
                                    </EmptyStateActions>
                                </EmptyStateFooter>
                            </EmptyState>
                        </CardBody>
                    </Card>
                </div>
            </>
        )
    }

    return (
        <>
           <SubscriptionPageHeader/>
            <div
                style={{
                    padding: '3%',
                }}>
                <Card isFullHeight>
                    <CardBody>
                        <Table
                            columns={columns}
                            rows={subscriptionList}
                        >
                            <TableContextConsumer>
                                {() => {
                                    return (
                                        <TableToolbar
                                            customToolbarItems={[
                                                <>
                                                    <ToolbarGroup align={{ default: 'alignLeft' }}>
                                                        <ToolbarItem>
                                                            <Button
                                                                variant="secondary"
                                                                component="a"
                                                                target="_blank"
                                                                href={`${Env.PartnerConsolePortals[Env.getEnvName()]}subscriptions/inventory`}
                                                            >
                                                                Manage all subscriptions
                                                            </Button>
                                                        </ToolbarItem>
                                                    </ToolbarGroup>
                                                    <ToolbarGroup align={{ default: 'alignRight' }}>
                                                        <ToolbarItem>
                                                            <ToggleGroup
                                                                aria-label="Default with single selectable">
                                                                <ToggleGroupItem
                                                                    text="Active"
                                                                    buttonId="ACTIVE"
                                                                    isSelected={isSelected === 'ACTIVE'}
                                                                    onChange={handleItemClick}
                                                                />
                                                                <ToggleGroupItem
                                                                    text="Expired"
                                                                    buttonId="EXPIRED"
                                                                    isSelected={isSelected === 'EXPIRED'}
                                                                    onChange={handleItemClick}
                                                                />
                                                                <ToggleGroupItem
                                                                    text="All"
                                                                    buttonId="ALL"
                                                                    isSelected={isSelected === 'ALL'}
                                                                    onChange={handleItemClick}
                                                                />
                                                            </ToggleGroup>
                                                        </ToolbarItem>
                                                    </ToolbarGroup>
                                                </>,
                                            ]}
                                            ttWrapperClassName="team-members-list-toolbar">
                                            <PFTableV2
                                                variant={'compact'}
                                                initialSortByData={{ index: 3,direction: 'desc' }}
                                                aria="Team members"
                                                isLoading={isPartnerSubsInfoFetching}
                                                isStriped
                                            />
                                        </TableToolbar>
                                    );
                                }}
                            </TableContextConsumer>
                        </Table>
                    </CardBody>
                </Card>
            </div>
        </>
    )
}
