import Env from '../utils/env';

export const PartnerBenefitsUrl = `${
    Env.ConnectPortalUrls[Env.getEnvName()]
}en/benefits-of-being-a-partner `;
export const PartnerProgramsOfferUrl = `${Env.ConnectPortalUrls[Env.getEnvName()]}en/programs `;

export const JoinExistingPartnerCompanyUrl = `${
    Env.ConnectPortalUrls[Env.getEnvName()]
}join-existing-partner-company `;

const TRAINING_PORTAL_BASE_URL="https://training-lms.redhat.com/sso/saml/auth/rhopen?RelayState="

export const COURSE_LINK = TRAINING_PORTAL_BASE_URL+'deeplinklp%3D';
export const ALL_COURSES_LINK= TRAINING_PORTAL_BASE_URL+"clmsbrowsev2.prMain%253Fin_sessionId%253D%2524XLR8_SESSIONID%2524";
export const ENROLLED_PARTNER_COURSES_LINK= TRAINING_PORTAL_BASE_URL+"clmsCurrentLearning.prMain%253Fin_sessionId%253D%2524XLR8_SESSIONID%2524"
export const DOCUMENTATION_Link = "https://redhat-connect.gitbook.io/red-hat-partner-connect-general-guide/managing-your-account/product-listing"
