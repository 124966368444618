import {
    Button,
    Card,
    CardBody,
    Flex,
    FlexItem,
    Text,
    TextContent,
    TextVariants,
} from '@patternfly/react-core';
import appDevelopment from '../../../../../assets/app-development.png';
import './AddProductInfoCard.scss';
import Env from '../../../../../utils/env';
type AddProductInfoCardProps = {
    heading: string;
};

export const AddProductInfoCard = ({heading}:AddProductInfoCardProps)   => {
    return (
        <Card className={'add-product-info-card'}>
            <CardBody>
                <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }}
                      alignItems={{ default: 'alignItemsFlexStart' }}>
                    <FlexItem>
                        <TextContent>
                            <Text component={TextVariants.h3}>{heading}</Text>
                        </TextContent>
                        <Button isInline variant="link" component={'a'} href={`${Env.ConnectPortalUrls[Env.getEnvName()]}manage/products`} target="_blank">
                            Add a new product
                        </Button>
                    </FlexItem>
                    <FlexItem>
                        <img aria-hidden={true} alt={"application development"} src={appDevelopment} />
                    </FlexItem>
                </Flex>
            </CardBody>
        </Card>
    )

}
