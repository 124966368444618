import { FC } from 'react';
import { Banner, Text, TextContent } from '@patternfly/react-core';
import './DashboardHeader.scss';

const DashboardHeader: FC = () => {
    const userInfo = window?.sessionjs?.getuserInfo();
    const fullName = userInfo !== undefined ? `${userInfo?.firstName} ${userInfo?.lastName}` : '';
    return (
        <Banner className='dashboard-header'>
            <TextContent>
                <Text component={'h1'} className='dashboard-header__name'>Hi, {fullName}</Text>
                <Text component={'h2'} className='dashboard-header__subtitle'>Welcome to the partner portal.</Text>
            </TextContent>
        </Banner>
    );
};

export default DashboardHeader;
