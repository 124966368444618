import { FC } from 'react';
import { ActionGroup, Alert, Button, Form, Text, TextContent } from '@patternfly/react-core';
import Env from '../../utils/env';

const Step3Finish: FC = () => {
    return (
        <div className='wizard-main'>
            <Alert
                style={{
                    marginBottom: '2em',
                }}
                variant={'success'}
                title={<TextContent><Text component={'h3'}>You have successfully claimed your free partner subscription</Text></TextContent>}
                isInline={true}
            />
            <TextContent>
                <Text className='page-description'>
                    You are now able to use the Red Hat Certification Tool to certify your products.
                </Text>
            </TextContent>
            <Form>
                <ActionGroup className='button-group'>
                    <Button
                        onClick={() => {
                            window.location.href = Env.RHCERTURLs[Env.getEnvName()];
                        }}
                        variant='primary'>
                        Go to the Red Hat Certification Tool
                    </Button>
                </ActionGroup>
            </Form>
        </div>
    );
};

export default Step3Finish;
