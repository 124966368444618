import GraduationCap from '../assets/Icon-Red_Hat-Graduation_cap-A-Red-RGB.png';
import DownloadIcon from '../assets/Icon-Red_Hat-Download-A-Red-RGB.png';
import WebsiteIcon from '../assets/Icon-Red_Hat-Website_system-A-Red-RGB.png';
import CeoIcon from '../assets/Icon-Red_Hat-CEO_businessleader-A-Red-RGB.png';
import MoneyStackIcon from '../assets/Icon-Red_Hat-Money_stack-A-Red-RGB.png';
import EnvelopIcon from '../assets/Icon-Red_Hat-Envelope-A-Red-RGB.png';
import userIcon from '../assets/Icon-Red_Hat-User-A-Red-RGB.png';
import CertificationIcon from '../assets/Icon-Red_Hat-Certification-A-Red-RGB.png';
import BooksIcon from '../assets/Icon-Red_Hat-Books_multiple-A-Red-RGB.png';
import SupportIcon from '../assets/Icon-Red_Hat-Support-A-Red-RGB.png';
import FlagIcon from '../assets/Icon-Red_Hat-Flag-A-Red-RGB.png';
import KioskIcon from '../assets/Icon-Red_Hat-KoaLA_kiosk-A-Red-RGB.png';
import { IPartnerInfo, IPartnershipStatus } from './apiconstants-pfs';
import Env from '../utils/env';
import _ from 'lodash';
import { Text, TextVariants } from '@patternfly/react-core';

export const displayDashboardCards = (
    partnerInformationDetails: IPartnerInfo | undefined,
    partnerInfoLoading: boolean | undefined,
    partnershipStatus: IPartnershipStatus | undefined,
    partnershipStatusLoading: boolean | undefined
) => {
    const getSubscriptionTileDetails = () => {
        if (partnerInfoLoading || !partnerInformationDetails) {
            return {
                description: null,
                label: null,
                link: null,
                isSelectable: true,
            };
        }
        let cardDescription = null;
        let cardLabel = null;
        let cardLink = null;
        let isCardSelectable = true;
        const orgAdmin = partnerInformationDetails?.orgAdmin;
        const activeEndSubscriptionDate = new Date(
            _.sortBy(
                partnerInformationDetails?.subscriptionStatus?.subscriptions,
                'effectiveEndDate'
            )[0]?.effectiveEndDate
        ).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        if (
            partnerInformationDetails?.subscriptionStatus?.active &&
            !partnerInformationDetails?.subscriptionStatus?.available
        ) {
            cardDescription = `Your subscription is active. It expires on ${activeEndSubscriptionDate}`;
            cardLabel = 'Download software';
            cardLink = `${Env.AccessRedhatPortals[Env.getEnvName()]}downloads`;
        } else if (partnerInformationDetails?.subscriptionStatus?.available && orgAdmin) {
            cardDescription =
                'Your subscription is not active. Request to access and download free software.';
            cardLabel = 'Request software';
            cardLink = `${Env.ConnectPortalUrls[Env.getEnvName()]}partner-apps/subscriptions`;
        } else if (partnerInformationDetails?.subscriptionStatus?.available && !orgAdmin) {
            cardDescription =
                'Your subscription is not active. Your organization administrator needs to request the subscription.';
            cardLabel = null;
            cardLink = null;
            isCardSelectable = false;
        }
        return {
            description: cardDescription,
            label: cardLabel,
            link: cardLink,
            isSelectable: isCardSelectable,
        };
    };
    const subscriptionTileDetails = getSubscriptionTileDetails();
    return {
        dashboardTopRowSection: [
            {
                title: 'Sales opportunities',
                description:
                    'Register deals, manage your sales and view your partner program status on our new sales platform.',
                subtext: 'Currently limited to resellers, distributors, and sell-with partners.',
                label: 'Visit sales homepage',
                link:
                    Env.getEnvName() === Env.EnvNames.PROD
                        ? 'https://partners.connect.redhat.com/'
                        : 'https://partners.connect.uat.redhat.com/',
                visibility: partnershipStatus?.businessPartner,
                icon: FlagIcon,
                openNewTab: false,
                isLoading: false,
                isSelectable: true,
                titleInPlainText: null,
            },
            {
                title: 'Manage your business account',
                description: 'Access your agreements, manage users, view company information and historical sales.',
                label: 'Manage your account',
                link: `${Env.BusinessPartnerPortalUrls[Env.getEnvName()]}`,
                visibility: partnershipStatus?.businessPartner,
                icon: CeoIcon,
                openNewTab: false,
                isLoading: partnershipStatusLoading,
                isSelectable: true,
                titleInPlainText: null,
            },
            {
                title: 'Software certification',
                description: 'Access tools to certify your products on Red Hat platforms',
                label: 'Certify your software',
                link: `${Env.ConnectPortalUrls[Env.getEnvName()]}account/dashboard`,
                visibility: partnershipStatus?.techPartner,
                icon: CertificationIcon,
                openNewTab: false,
                isLoading: partnershipStatusLoading,
                isSelectable: true,
                titleInPlainText: null,
            },
            {
                title: (
                    <Text component={TextVariants.h6}>
                        Deal registration (LATAM&nbsp;&amp;&nbsp;APAC)
                    </Text>
                ),
                description:
                    'Register opportunities and receive special incentives when deals are closed',
                label: 'Register an opportunity',
                link: 'https://partnercenter.redhat.com/RegistrationHome',
                visibility: false,
                icon: FlagIcon,
                openNewTab: false,
                isLoading: false,
                isSelectable: true,
                titleInPlainText: `Deal registration (LATAM & APAC)`,
            },
            {
                title: 'Market development funds',
                description: 'Plan, prioritize, and manage MDF activities',
                label: 'Manage MDF',
                link: 'https://connect.redhat.com/account/dashboard',
                visibility: false, // Display rules needs clarity CONNCERT-1907
                icon: MoneyStackIcon,
                openNewTab: false,
                isLoading: false,
                isSelectable: true,
                titleInPlainText: null,
            },
            {
                title: 'Red Hat Partner Training Portal',
                description:
                    'Role-based training for sellers, technical sellers, & delivery specialists',
                label: 'Start learning',
                link: 'https://training-lms.redhat.com/sso/saml/auth/rhopen',
                visibility: false,
                icon: GraduationCap,
                openNewTab: false,
                isLoading: false,
                isSelectable: true,
                titleInPlainText: null,
            },
            {
                title: 'Partner website home page',
                description:
                    'Access information about partner programs and benefits, and the partner blog',
                label: 'Access partner website home page',
                link: `${Env.ConnectPortalUrls[Env.getEnvName()]}`,
                visibility: false,
                icon: WebsiteIcon,
                openNewTab: false,
                isLoading: false,
                isSelectable: true,
                titleInPlainText: null,
            },
        ],
        dashboardBottomRowSection: [
            {
                title: 'Access library of marketing, sales & technical content',
                description: 'Library of marketing, sales & technical content',
                label: 'Browse digital assets',
                link: `${Env.ContentCenterPortals[Env.getEnvName()]}`,
                visibility: true,
                icon: BooksIcon,
                openNewTab: false,
                isLoading: false,
                isSelectable: true,
                titleInPlainText: null,
            },
            {
                title: 'Red Hat Demo Platform',
                description: 'Hands-on assets for learning and showcasing Red Hat solutions',
                label: 'Access demo platform',
                link: 'https://partner.demo.redhat.com/?oauthProviderRedirect=sso',
                visibility: true,
                icon: KioskIcon,
                openNewTab: false,
                isLoading: false,
                isSelectable: true,
                titleInPlainText: null,
            },
            {
                title: 'Partner support',
                description:
                    'For any and all questions about your partnering activities with Red Hat',
                label: 'Get help',
                link: `${Env.ConnectPortalUrls[Env.getEnvName()]}en/support`,
                visibility: false,
                icon: SupportIcon,
                openNewTab: false,
                isLoading: false,
                isSelectable: true,
                titleInPlainText: null
            },
            {
                title: 'Partner email preferences',
                description: 'Select areas of interest for your personalized emails from Red Hat',
                label: 'Manage email preferences',
                link: `${Env.ConnectPortalUrls[Env.getEnvName()]}en/partner-preferences`,
                visibility: true,
                icon: EnvelopIcon,
                openNewTab: false,
                isLoading: false,
                isSelectable: true,
                titleInPlainText: null
            },
            {
                title: 'User account',
                description:
                    'Edit your contact info, password, location preferences, and errata notifications',
                label: 'Access my account',
                link: `${Env.UGCPortals[Env.getEnvName()]}wapps/ugc/protected/personalInfo.html`,
                visibility: true,
                icon: userIcon,
                openNewTab: false,
                isLoading: false,
                isSelectable: true,
                titleInPlainText: null
            },
            {
                title: 'Partner subscriptions',
                description: subscriptionTileDetails.description,
                label: subscriptionTileDetails.label,
                link: subscriptionTileDetails.link,
                visibility: partnerInfoLoading ? true : !!subscriptionTileDetails?.description,
                icon: DownloadIcon,
                openNewTab: false,
                isLoading: partnerInfoLoading,
                isSelectable: subscriptionTileDetails.isSelectable,
                titleInPlainText: null
            }
        ],
    };
};
