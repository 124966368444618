import { useMemo } from "react";
import { ICheckListItem,ProductScoreResponse } from '../constants/products'
import { useQuery } from 'react-query';
import { getProductScoreStatus } from '../api/pfs'

interface productScoreItem {
    title: string,
    description: string,
    buttonText: string,
    buttonType: "link" | "external",
    buttonPath: string
}
const getAPIProductScoreItems = (productScoreResponse: ProductScoreResponse | undefined ): productScoreItem[] => {
    const apiScoreItems: productScoreItem[] = [];
    productScoreResponse?.items
        ?.filter((item:ICheckListItem) => !item?.title?.startsWith('hidden_'))
        ?.forEach((checklistItem: ICheckListItem) => {
            apiScoreItems.push({
                title: checklistItem.title,
                description: checklistItem?.suggestion,
                buttonPath: checklistItem?.checklistItemActions?.[0]?.link,
                buttonText: checklistItem?.checklistItemActions?.[0]?.label,
                buttonType: checklistItem?.checklistItemActions?.[0]?.link?.startsWith("https://")  ? "external" : "link"
            });
        });
    return apiScoreItems;
}
interface CalculateProductScoreMetadataType {
    scoreStatus: string,
    statusColor: string,
    graphValues: {
        value: number,
        color: string,
        minWidth?: string
    }[]
}
function calculateScoreMetadata(currentTotalScore: number, maxTotalScore?: number): CalculateProductScoreMetadataType {
    const MAX_SCORE = maxTotalScore || 29;
    const ranges = [
        { min: 1, max: 10, statusText: "Fair", color: '#0066cc', minWidth: '40%' },
        { min: 11, max: 20, statusText: "Good", color: '#009596' },
        { min: 21, max: 28, statusText: "Excellent", color: '#5ba352' },
        { min: 29, max: MAX_SCORE, statusText: "Best", color: '#92d402' }
    ];
    let statusResult = '';
    let statusColor = '';
    const graphValues: {value: number, color: string}[] = ranges.map(range => {
        const { min, max, statusText, color, minWidth } = range;
        // Status text and color Calculation
        if (currentTotalScore >= min) {
            statusResult = statusText;
            statusColor = color;
        }

        let graphFill: number;
        // Graph Calculation
        if (currentTotalScore < min) {
            graphFill =  0;
        } else if (currentTotalScore === MAX_SCORE) {
            graphFill =  100;
        } else {
            graphFill = Math.min((((currentTotalScore - min) / (max - min)) * 100), 100);
        }
        return {value: graphFill, color, ...(minWidth && { minWidth })};
    });
    return {
        graphValues,
        scoreStatus: statusResult,
        statusColor: statusColor
    }
}
interface ProductScoreAdditionalScoreMetadataType {
    productScore: number,
    isProductScoreLoading: boolean,
    apiProductScoreItems: productScoreItem[]
}

export type ProductScoreMetadataType = CalculateProductScoreMetadataType & ProductScoreAdditionalScoreMetadataType;
export const useProductScoreMetadata = (productId: string): ProductScoreMetadataType => {
    const {
        data: productScoreBackendResponse,
        isFetching: isProductScoreLoading
    }= useQuery<ProductScoreResponse | undefined>(['productScore', productId], ({signal}) => getProductScoreStatus(productId, signal))
    const {
        graphValues,
        scoreStatus,
        productScore,
        statusColor,
        apiProductScoreItems
    } = useMemo(() => {
            const productScore =  (productScoreBackendResponse?.totalScore || 0);
            const {graphValues, scoreStatus, statusColor} = calculateScoreMetadata(productScore, productScoreBackendResponse?.totalMaxScore);
            const apiProductScoreItems = getAPIProductScoreItems(productScoreBackendResponse);
            return {graphValues, scoreStatus, productScore, statusColor, apiProductScoreItems};
        }, [productScoreBackendResponse]);

    return {
        graphValues,
        scoreStatus,
        isProductScoreLoading,
        productScore,
        statusColor,
        apiProductScoreItems
    }
}
