import Uri from 'jsuri';

const EnvNames = {
    DEV: 'DEV',
    STAGE: 'STAGE',
    PROD: 'PROD',
    QA: 'QA',
    UAT: 'UAT',
};

const ConnectPortalUrls = {
    [EnvNames.DEV]: 'https://connect.qa.redhat.com/',
    [EnvNames.QA]: 'https://connect.qa.redhat.com/',
    [EnvNames.STAGE]: 'https://connect.stage.redhat.com/',
    [EnvNames.PROD]: 'https://connect.redhat.com/',
    [EnvNames.UAT]: 'https://connect.uat.redhat.com/',
};

const RHCERTURLs = {
    [EnvNames.DEV]: 'https://rhcert.connect.dev.redhat.com/',
    [EnvNames.QA]: 'https://rhcert.connect.dev.redhat.com/',
    [EnvNames.STAGE]: 'https://rhcert.connect.preprod.redhat.com/',
    [EnvNames.PROD]: 'https://rhcert.connect.redhat.com/',
    [EnvNames.UAT]: 'https://rhcert.connect.uat.redhat.com/',
};

const TrialAppPortalUrls = {
    [EnvNames.DEV]: 'https://cmsfte.dev.a1.vary.redhat.com/en/partners/',
    [EnvNames.QA]: 'https://cmsfte.dev.a1.vary.redhat.com/en/partners/',
    [EnvNames.STAGE]: 'https://www-admin.corp.stage.redhat.com/en/partners/',
    [EnvNames.PROD]: 'https://www.redhat.com/en/partners/',
};

const BusinessPartnerPortalUrls = {
    [EnvNames.DEV]: 'https://redhat--qa.sandbox.my.site.com/Dashboard_page',
    [EnvNames.QA]: 'https://redhat--qa.sandbox.my.site.com/Dashboard_page',
    [EnvNames.STAGE]: 'https://redhat--stage.sandbox.my.site.com/Dashboard_page',
    [EnvNames.PROD]: 'https://partnercenter.redhat.com/Dashboard_page',
};

const isConnectPortal = () => {
    return (
        [
            ConnectPortalUrls[EnvNames.PROD],
            ConnectPortalUrls[EnvNames.STAGE],
            ConnectPortalUrls[EnvNames.QA],
            ConnectPortalUrls[EnvNames.DEV],
            ConnectPortalUrls[EnvNames.UAT],
        ].indexOf(window.location.protocol + '//' + window.location.hostname + '/') !== -1
    );
};

// @ts-ignore
const getEnvName = (): string => {
    // Returns PROD | QA | FTE | CI | STAGE
    const prodHostNames = [
        'connect.redhat.com',
        'prod.foo.redhat.com',
        'rhc-product-listings-prod.ext.us-west.dc.prod.paas.redhat.com',
        'connect.uat.redhat.com'
    ];
    const qaHostNames = [
        'stage.foo.redhat.com',
        'connect.qa.redhat.com',
        'fte-connect.redhat.com',
        'rhc-product-listings-qa.ext.us-west.dc.preprod.paas.redhat.com',
    ];
    const stageHostNames = [
        // 'stage.foo.redhat.com',
        'connect.stage.redhat.com',
        'stage-connect.redhat.com',
        'rhc-product-listings-stage.ext.us-west.dc.preprod.paas.redhat.com',
    ];
    const devHostNames = [
        'dev-connect.redhat.com',
        'rhc4tp-sandbox1.devlab.redhat.com',
        'rhc4tp-sandbox2.devlab.redhat.com',
        'rhc4tp-sandbox3.devlab.redhat.com',
        'rhc4tp-sandbox4.devlab.redhat.com',
        'rhc4tp-sandbox5.devlab.redhat.com',
        'rhc4tp-mr-app01.devlab.redhat.com',
        'rhc4tp-mr-app02.devlab.redhat.com',
        'rhc4tp-mr-app03.devlab.redhat.com',
        'rhc4tp-mr-app04.devlab.redhat.com',
        'rhc4tp-mr-app05.devlab.redhat.com',
        'rhc4tp-bdunn.devlab.redhat.com',
        'rhc4tp-rcoffman.devlab.redhat.com',
        'rhc4tp.localhost',
        'rhc-product-listings-dev.ext.us-west.dc.preprod.paas.redhat.com',
    ];
    const uatHostNames: any[] = [];

    if (typeof window !== 'undefined' && window) {
        if (prodHostNames.indexOf(window.location.hostname) !== -1) {
            return EnvNames.PROD;
        } else if (uatHostNames.indexOf(window.location.hostname) !== -1) {
            return EnvNames.UAT;
        } else if (stageHostNames.indexOf(window.location.hostname) !== -1) {
            return EnvNames.STAGE;
        } else if (qaHostNames.indexOf(window.location.hostname) !== -1) {
            return EnvNames.QA;
        } else if (devHostNames.indexOf(window.location.hostname) !== -1) {
            return EnvNames.DEV;
        } else {
            if (typeof jest === 'undefined') {
                console.error('Cannot find the environment name. Please add it to the env array');
            }
        }
    }
};
const getPFSAPIUrl = () => {
    const envQueryParam = new Uri(window.location.search).getQueryParamValue('env');
    switch (envQueryParam ? envQueryParam : getEnvName()) {
        case EnvNames.DEV:
            return new Uri('https://connect.qa.redhat.com');
        case EnvNames.QA:
            return new Uri('https://connect.qa.redhat.com');
        case EnvNames.STAGE:
            return new Uri('https://connect.stage.redhat.com');
        case EnvNames.PROD:
            return new Uri('https://connect.redhat.com');
        default:
            return new Uri('https://connect.qa.redhat.com');
    }
};

const getSftpHostUrl = () => {
    const envQueryParam = new Uri(window.location.search).getQueryParamValue('env');
    switch (envQueryParam ? envQueryParam : getEnvName()) {
        case EnvNames.DEV:
            return 'partners.dev.redhat.com';
        case EnvNames.QA:
            return 'partners.qa.redhat.com';
        case EnvNames.STAGE:
            return 'sftp.connect.stage.redhat.com';
        case EnvNames.PROD:
            return 'sftp.connect.redhat.com';
        default:
            return 'partners.qa.redhat.com';
    }
};

const getAccountsAPIUrl = () => {
    if (typeof window !== 'undefined' && window) {
        const envQueryParam = new Uri(window.location.search).getQueryParamValue('env');
        switch (envQueryParam ? envQueryParam : getEnvName()) {
            case EnvNames.DEV:
                return new Uri('https://api.access.qa.redhat.com');
            case EnvNames.QA:
                return new Uri('https://api.access.qa.redhat.com');
            case EnvNames.STAGE:
                return new Uri('https://api.access.stage.redhat.com');
            case EnvNames.UAT:
                return new Uri('https://api.access.redhat.com');
            case EnvNames.PROD:
                return new Uri('https://api.access.redhat.com');
            default:
                return new Uri('https://api.access.qa.redhat.com');
        }
    }
};

const getAPIURLS = () => {
    if (typeof window !== 'undefined' && window) {
        const envQueryParam = new Uri(window.location.search).getQueryParamValue('env');
        switch (envQueryParam ? envQueryParam : getEnvName()) {
            case EnvNames.DEV:
                return new Uri('https://connect.qa.redhat.com');
            case EnvNames.QA:
                return new Uri('https://connect.qa.redhat.com');
            case EnvNames.STAGE:
                return new Uri('https://connect.stage.redhat.com');
            case EnvNames.UAT:
                return new Uri('https://connect.uat.redhat.com');
            case EnvNames.PROD:
                return new Uri('https://connect.redhat.com');
            default:
                return new Uri('https://connect.qa.redhat.com');
        }
    }
};

const UGCPortalUrls = {
    [EnvNames.DEV]: 'https://www.dev.redhat.com/',
    [EnvNames.QA]: 'https://www.qa.redhat.com/',
    [EnvNames.STAGE]: 'https://www.stage.redhat.com/',
    [EnvNames.UAT]: 'https://www.uat.redhat.com/',
    [EnvNames.PROD]: 'https://www.redhat.com/',
};

const AccessRedhatPortals = {
    [EnvNames.DEV]: 'https://access.qa.redhat.com/',
    [EnvNames.QA]: 'https://access.qa.redhat.com/',
    [EnvNames.STAGE]: 'https://access.stage.redhat.com/',
    [EnvNames.PROD]: 'https://access.redhat.com/',
};

const DevelopersRedhatPortals = {
    [EnvNames.DEV]: 'https://developers.qa.redhat.com/',
    [EnvNames.QA]: 'https://developers.qa.redhat.com/',
    [EnvNames.STAGE]: 'https://developers.stage.redhat.com/',
    [EnvNames.PROD]: 'https://developers.redhat.com/',
};

const CatalogPortals = {
    [EnvNames.DEV]: 'https://catalog.qa.redhat.com/',
    [EnvNames.QA]: 'https://catalog.qa.redhat.com/',
    [EnvNames.STAGE]: 'https://catalog.stage.redhat.com/',
    [EnvNames.PROD]: 'https://catalog.redhat.com/',
};

const ContentCenterPortals = {
    [EnvNames.DEV]: 'https://content.stage.redhat.com/content/rhcc/us/en/homepage-internal.html',
    [EnvNames.QA]: 'https://content.stage.redhat.com/content/rhcc/us/en/homepage-internal.html',
    [EnvNames.STAGE]: 'https://content.stage.redhat.com/content/rhcc/us/en/homepage-internal.html',
    [EnvNames.PROD]: 'https://content.redhat.com',
};

const PartnerConsolePortals = {
    [EnvNames.DEV]: 'https://console.dev.redhat.com/',
    [EnvNames.QA]: 'https://console.dev.redhat.com/',
    [EnvNames.STAGE]: 'https://console.stage.redhat.com/',
    [EnvNames.PROD]: 'https://console.redhat.com/',
}

const getHydraProxyUrl = () => {
    if (typeof window !== 'undefined' && window) {
        const envQueryParam = new Uri(window.location.search).getQueryParamValue('env');
        switch (envQueryParam ? envQueryParam : getEnvName()) {
            case EnvNames.DEV:
                return new Uri('https://access.qa.redhat.com');
            case EnvNames.QA:
                return new Uri('https://access.qa.redhat.com');
            case EnvNames.STAGE:
                return new Uri('https://access.stage.redhat.com');
            case EnvNames.UAT:
                return new Uri('https://access.uat.redhat.com');
            case EnvNames.PROD:
                return new Uri('https://access.redhat.com');
            default:
                return new Uri('https://access.qa.redhat.com');
        }
    }
};

const getHydraAPIUrl = () => {
    if (typeof window !== 'undefined' && window) {
        const envQueryParam = new Uri(window.location.search).getQueryParamValue('env');
        switch (envQueryParam ? envQueryParam : getEnvName()) {
            case EnvNames.DEV:
                return new Uri('https://connect.qa.redhat.com');
            case EnvNames.QA:
                return new Uri('https://connect.qa.redhat.com');
            case EnvNames.STAGE:
                return new Uri('https://connect.stage.redhat.com');
            case EnvNames.UAT:
                return new Uri('https://connect.uat.redhat.com');
            case EnvNames.PROD:
                return new Uri('https://connect.redhat.com');
            default:
                return new Uri('https://connect.qa.redhat.com');
        }
    }
};
export const getUserInfoFromJWT = () => {
    const jwtData = window?.sessionjs?.getuserInfo();
    return {
        accountId: jwtData?.account_id,
        accountNumber: jwtData?.account_number,
        isInternal: jwtData?.internal,
        id: jwtData?.id,
        userId: jwtData?.user_id,
        username: jwtData?.account_number,
    };
}

const getPyxisURL = () => {
    const envQueryParam = new Uri(window.location.search).getQueryParamValue('env');
    switch (envQueryParam ? envQueryParam : getEnvName()) {
        case EnvNames.DEV:
            return new Uri('https://catalog.qa.redhat.com');
        case EnvNames.QA:
            return new Uri('https://catalog.qa.redhat.com');
        case EnvNames.STAGE:
            return new Uri('https://catalog.stage.redhat.com');
        case EnvNames.UAT:
            return new Uri('https://catalog.uat.redhat.com');
        case EnvNames.PROD:
            return new Uri('https://catalog.redhat.com');
        default:
            return new Uri('https://catalog.qa.redhat.com');
    }
};

const getPyxisGraphQlAPIUrl = () => {
    if (typeof window !== 'undefined' && window) {
        const envQueryParam = new Uri(window.location.search).getQueryParamValue('env')
        switch(envQueryParam ? envQueryParam : getEnvName()) {
            case EnvNames.DEV:
                return new Uri('https://catalog.qa.redhat.com/api/containers/graphql/');
            case EnvNames.QA:
                return new Uri('https://catalog.qa.redhat.com/api/containers/graphql/');
            case EnvNames.STAGE:
                return new Uri('https://catalog.stage.redhat.com/api/containers/graphql/');
            case EnvNames.UAT:
                return new Uri('https://catalog.uat.redhat.com/api/containers/graphql/');
            case EnvNames.PROD:
                return new Uri('https://catalog.redhat.com/api/containers/graphql/');
            default:
                return new Uri('https://catalog.qa.redhat.com/api/containers/graphql/');
        }
    }
};


const getSuperGrapqlAPIs = () => {
    if (typeof window !== 'undefined' && window) {
        const envQueryParam = new Uri(window.location.search).getQueryParamValue('env')
        switch(envQueryParam ? envQueryParam : getEnvName()) {
            case EnvNames.DEV:
                return new Uri('https://graphql.dev.redhat.com/');
            case EnvNames.QA:
                return new Uri('https://graphql.qa.redhat.com/');
            case EnvNames.STAGE:
                return new Uri('https://graphql.stage.redhat.com/');
            case EnvNames.UAT:
                return new Uri('https://graphql.redhat.com/');
            case EnvNames.PROD:
                return new Uri('https://graphql.redhat.com/');
            default:
                return new Uri('https://graphql.qa.redhat.com/');
        }
    }
};
export default class Env {
    static routePrefix = '/partner-admin';
    static plsPrefix = '/manage';
    static sftpHost = getSftpHostUrl();
    static getEnvName = getEnvName;
    static ConnectPortalUrls = ConnectPortalUrls;
    static RHCERTURLs = RHCERTURLs;
    static isConnectPortal = isConnectPortal;
    static pfsAPIUrl = getPFSAPIUrl();
    static pfsPSPrefix = '/pfs/ps';
    static pfsApiPrefix = '/pfs/sftp';
    static apiURLS = getAPIURLS();
    static accountsAPIPrefix = '/account/v1';
    static accountsAPIUrl = getAccountsAPIUrl();
    static UGCPortals = UGCPortalUrls;
    static TrialAppPortalUrls = TrialAppPortalUrls;
    static BusinessPartnerPortalUrls = BusinessPartnerPortalUrls;
    static AccessRedhatPortals = AccessRedhatPortals;
    static EnvNames = EnvNames;
    static hydraProxyURL = getHydraProxyUrl();
    static hydraRestPrefix = '/hydra/rest/configuration';
    static hydraAPIPrefix = '/hydra/prm/v1';
    static hydraURL = getHydraAPIUrl();
    static DevelopersRedhatPortals = DevelopersRedhatPortals;
    static CatalogPortals = CatalogPortals;
    static ContentCenterPortals = ContentCenterPortals;
    static isDevEnv = window?.location?.host?.startsWith('stage.foo.redhat.com');
    static pyxisURL = getPyxisURL();
    static pyxisAPIPrefix = '/api/containers/v1';
    static superGraphQl = getSuperGrapqlAPIs();
    static PartnerConsolePortals = PartnerConsolePortals;
    static pyxisGraphqlAPIURL = getPyxisGraphQlAPIUrl();
    static pfsCSPrefix = '/pfs/cs';
}

export const SAMPLE_JWT_TOKEN = 'test-jwt';
export const MONGOID_COOKIE = 'mongoid';
