import React,{ FC } from 'react'
import {
    Card,
    CardBody,
    Flex,
    FlexItem,
    Grid,
    GridItem,
    Tabs,
    Tab,
    TabTitleText,
    TabContent,
    CardFooter,
    Button,
} from '@patternfly/react-core';
import UnfinishedProductCard from './UnfinishedProductCard';
import Env from '../../../../utils/env';
import { ActiveProductsHeader } from '../../../../utils/productListUtils';
import PublishedProductCard from './PublishedProductCard';

interface IActiveProductsCardProps {
    activeKey: number
}

const ActiveProductsCard: FC<IActiveProductsCardProps> = ({ activeKey }) => {
    const [activeTabKey,setActiveTabKey] = React.useState<string | number>(activeKey | 0);
    const unfinishedProductsRef = React.createRef<HTMLElement>();
    const publishedProductsRef = React.createRef<HTMLElement>();
    const handleTabClick = (
        event: React.MouseEvent<any> | React.KeyboardEvent | MouseEvent,
        tabIndex: string | number,
    ) => {
        setActiveTabKey(tabIndex);
    };

    return (
        <Card isFullHeight>
            <ActiveProductsHeader />
            <CardBody>
                <Grid hasGutter>
                    <GridItem span={3}>
                        <Tabs
                            activeKey={activeTabKey}
                            isVertical={true}
                            onSelect={handleTabClick}
                            aria-label="Tab showing unfinished and published products"
                            role="region"
                        >
                            <Tab
                                eventKey={0}
                                title={<TabTitleText>Unfinished product listings</TabTitleText>}
                                tabContentId="unfinishedProducts"
                                tabContentRef={unfinishedProductsRef}
                            />
                            <Tab
                                eventKey={1}
                                title={<TabTitleText>Published products</TabTitleText>}
                                tabContentId="publishedProducts"
                                tabContentRef={publishedProductsRef}
                            />
                        </Tabs>
                    </GridItem>
                    <GridItem span={9}>
                        <TabContent eventKey={0} id="unfinishedProducts"
                                    aria-label="This is content for unfinished products tab"
                                    ref={unfinishedProductsRef} hidden={activeTabKey === 1}>
                            <UnfinishedProductCard />
                        </TabContent>
                        <TabContent eventKey={1} id="publishedProducts"
                                    aria-label="This is content for published products tab"
                                    ref={publishedProductsRef} hidden={activeTabKey === 0}>
                            <PublishedProductCard />
                        </TabContent>
                    </GridItem>
                </Grid>
            </CardBody>
            <CardFooter>
                <Flex>
                    <FlexItem>
                        <Button variant="link" component={'a'}
                                href={`${Env.ConnectPortalUrls[Env.getEnvName()]}manage/products`}
                        >
                            View all products
                        </Button>
                    </FlexItem>
                    <FlexItem>
                        <Button variant="link" component={'a'}
                                href={`${Env.ConnectPortalUrls[Env.getEnvName()]}manage/products?create=true`}
                        >
                            Add a new product
                        </Button>
                    </FlexItem>
                </Flex>
            </CardFooter>
        </Card>
    );
}

export default ActiveProductsCard;
