import React from 'react';
import { UnauthenticatedDashboard } from './UnauthenticatedDashboard';
import { useQuery } from 'react-query';
import { IPartnerInfo, IPartnershipStatus } from '../../constants/apiconstants-pfs';
import { Bullseye } from '@patternfly/react-core';
import { IErrorType } from '../../constants/apiconstants-hydra';
import { getLoggedinPartnersInfo, getPartnershipStatus } from '../../api/pfs';
import ErrorPage from '../../shared/ErrorPage/ErrorPage';
import { Redirect, Route, Switch } from 'react-router-dom';
import Env from '../../utils/env';
import { AuthenticatedDashBoard } from './AuthenticatedDashBoard';
import './AuthenticatedDashboard.scss';
import DashboardHeader from './DashboardHeader/DashboardHeader';

export const DashBoardWrapper: React.FC = () => {
    const {
        error: partnershipStatusError,
        isFetching,
        data: partnershipStatus,
    } = useQuery<IPartnershipStatus, IErrorType>(['partnershipStatus'], async ({ signal }) => {
        return await getPartnershipStatus(signal);
    });
    const { error: partnerInfoError } = useQuery<IPartnerInfo, IErrorType>(
        ['partnerInfo'],
        async ({ signal }) => {
            return await getLoggedinPartnersInfo(true, signal);
        },
        {
            enabled: partnershipStatus?.isPartner,
        }
    );

    if (isFetching) {
        return <></>;
    }
    const error = partnerInfoError || partnershipStatusError;
    if (error) {
        if (error?.status === 403) {
            return (
                <ErrorPage
                    title='Unauthorized'
                    subTitle="Looks like you don't have access to the page you requested"
                    subSubTitle='Please retry after sometime.'
                    secondaryMessage="We've logged this 403 error, but you can also provide more information."
                />
            );
        }
        return (
            <Bullseye>
                <div>{error?.details || 'Something went wrong, Please retry after sometime.'}</div>
            </Bullseye>
        );
    }
    return (
        <div>
            <DashboardHeader />
            <div
                style={{
                    padding: '3%',
                }}>
                <Switch>
                    {partnershipStatus?.isPartner && (
                        <Redirect
                            exact={true}
                            from={`${Env.routePrefix}/not-a-partner`}
                            to={`${Env.routePrefix}/dashboard`}
                        />
                    )}
                    {!partnershipStatus?.isPartner && (
                        <Redirect
                            exact={true}
                            from={`${Env.routePrefix}/dashboard`}
                            to={`${Env.routePrefix}/not-a-partner`}
                        />
                    )}
                    {!partnershipStatus?.isPartner && (
                        <Route
                            exact={true}
                            path={`${Env.routePrefix}/not-a-partner`}
                            component={UnauthenticatedDashboard}
                        />
                    )}
                    {partnershipStatus?.isPartner && (
                        <Route
                            exact={true}
                            path={`${Env.routePrefix}/dashboard`}
                            component={AuthenticatedDashBoard}
                        />
                    )}
                </Switch>
            </div>
        </div>
    );
};
