import { FC,ReactNode } from "react";
import { GridItem } from "@patternfly/react-core";

interface HalfGridCardWrapperProps {
    children: ReactNode;
}

const HalfGridCardWrapper: FC<HalfGridCardWrapperProps> = ({children}) => (
    <GridItem sm={12} md={6} lg={6}>{children}</GridItem>);

export default HalfGridCardWrapper;
