import { FC, ReactElement } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Flex,
    FlexItem,
    Skeleton,
    Stack,
    StackItem,
    Text,
    TextContent,
    TextVariants,
    Title,
} from '@patternfly/react-core';
import { ArrowRightIcon } from '@patternfly/react-icons';

export interface DashboardCardProps {
    link: string | { link: string,label: string,openNewTab: boolean }[] | null;
    openNewTab: boolean;
    icon: string;
    title: string | ReactElement;
    description: string | null;
    subtext?: string;
    label: string | null;
    isLoading?: boolean | undefined;
    isSelectable?: boolean;
    mainSectionTitle?: string;
    plainTextTitle?: string | null;
    variant?: 'top' | 'inline';
    cardBody?: ReactElement;
    hasLabelIcon?:boolean;
}

export const DashboardCard: FC<DashboardCardProps> = ({
    link,
    openNewTab,
    icon,
    title,
    description,
    subtext,
    label,
    isLoading,
    isSelectable,
    mainSectionTitle,
    plainTextTitle,
    cardBody,
    variant = 'top',
    hasLabelIcon=true
}) => {
    if (isLoading && variant==="top") {
        return (
            <Card isFullHeight>
                <div className='authenticated-dashboard__img_wrapper'>
                    <Skeleton height={`45px`} width={'45px'} />
                </div>
                <CardBody>
                    <Stack hasGutter>
                        <StackItem>
                            <Skeleton fontSize='md' />
                        </StackItem>
                        <StackItem>
                            <Skeleton fontSize='md' />
                            <Skeleton fontSize='md' />
                        </StackItem>
                    </Stack>
                </CardBody>
                <CardFooter>
                    <Skeleton fontSize='md' />
                </CardFooter>
            </Card>
        );
    }
    return (
        <>
            {variant === 'top' ?
                <Card
                    className="authenticated-dashboard__card authenticated-dashboard__card__top-variant"
                    isFullHeight
                    {...(typeof link === 'string' && link
                        ? {
                            href: link,
                            component: "a",
                            ...(mainSectionTitle
                                ? {
                                    'data-analytics-track-by-analytics-manager':"true",
                                    'data-analytics-linktype': `cta`,
                                    'data-analytics-text': `${
                                        typeof title === 'string' ? title : plainTextTitle
                                    }`,
                                    'data-analytics-category': `${mainSectionTitle}|${
                                        typeof title === 'string' ? title : plainTextTitle
                                    }`,
                                }
                                : {}),
                        }
                        : {})}
                    {...(openNewTab ? { target: '_blank' } : {})}
                    aria-label={`${
                        typeof title === 'string' ? title : plainTextTitle
                    } Card. ${ description ?  description : null}. Press enter to ${label}`}
                    isSelectable={isSelectable}
                    >
                    <div className="authenticated-dashboard__img_wrapper">
                        <img src={icon} aria-hidden={true} alt={description || 'logo'}
                             className="authenticated-dashboard__img" />
                    </div>
                    <CardHeader>
                        <TextContent>
                            {typeof title === 'string' ? (
                                <Text aria-hidden={true} component={TextVariants.h3}>{title}</Text>
                            ) : (
                                title
                            )}
                        </TextContent>
                    </CardHeader>
                    <CardBody>
                        <TextContent>
                            <Text component={TextVariants.p}>
                                {description}
                                {subtext && (
                                    <Text
                                        component={TextVariants.small}
                                        style={{ color: '#1f1f1f',fontStyle: 'italic' }}>
                                        {subtext}
                                    </Text>
                                )}
                            </Text>
                        </TextContent>
                    </CardBody>
                    {label && (
                        <CardFooter>
                            <Button
                                variant={'link'}
                                icon={<ArrowRightIcon />}
                                iconPosition={'right'}
                                isInline>
                                {label}
                            </Button>
                        </CardFooter>
                    )}
                </Card>
                :
                <Card
                    className="authenticated-dashboard__card"
                    isFullHeight
                >
                    <CardHeader>
                        <Flex direction={{ default: 'row' }}
                              alignItems={{ default: 'alignItemsCenter' }}>
                            <FlexItem spacer={{ default: 'spacerSm' }}>
                                <Title headingLevel="h3">{title}</Title>
                            </FlexItem>
                            {icon &&
                                <FlexItem>
                                    <img src={icon} aria-hidden={true} alt={description || 'logo'}
                                         className="authenticated-dashboard__img" />
                                </FlexItem>
                            }
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        {cardBody}
                    </CardBody>
                    {link &&
                        <CardFooter>
                            {Array.isArray(link) &&
                                <Flex>
                                    {link?.map((elm,index) => {
                                        return (
                                            <FlexItem key={index}>
                                                <Button
                                                    component="a"
                                                    href={elm?.link}
                                                    variant={'link'}
                                                    {...elm?.openNewTab ? { target: '_blank' } : {}}
                                                    isInline>
                                                    {elm?.label}
                                                </Button>
                                            </FlexItem>
                                        )
                                    })}
                                </Flex>
                            }
                            {typeof link === 'string' &&
                                <Button
                                    component="a"
                                    href={link}
                                    variant={'link'}
                                    {...openNewTab ? { target: '_blank' } : {}}
                                    icon={hasLabelIcon && <ArrowRightIcon />}
                                    iconPosition={'right'}
                                    isInline>
                                    {label}
                                </Button>
                            }
                        </CardFooter>
                    }
                </Card>
            }
        </>
    );
};
