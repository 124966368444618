import './ErrorPage.scss';
import { ArrowRightIcon } from '@patternfly/react-icons';
import Env from "../../utils/env";

interface ErrorPageProps {
  title?: string;
  subTitle?: string;
  subSubTitle?: string;
  secondaryMessage?: string;
}
const ErrorPage = (prop: ErrorPageProps) => {
  const envName = Env.getEnvName();
  const feedbackURL = `${Env.ConnectPortalUrls[envName]}contact`;

  return (
    <div className='error-page-four-container'>
      <div className='error-main'>
        <div className='error-main--hero-gradient'>
          <div className='error-main-masthead'>
            <div className='error-main-masthead--content'>
              <h1 className='error-main-masthead--content--header'>{prop.title || "Not found."}</h1>
              <p className='error-main-masthead--content--para'>
                {prop.subTitle ||
                  "The page you’re looking for may have been moved or deleted."
                }
              </p>
              <p className='error-main-masthead--content--para'>
                {' '}
                {prop.subSubTitle || "The good news is that we can help."}
              </p>
            </div>
          </div>
        </div>
        <div className='error-main--relative-position--cards error-main--main'>
          <h2 className='error-main--main--header'>Try the following instead</h2>
          <div className='error-main--main--link-list'>
            <section>
              <h3>
                <a href={Env.ConnectPortalUrls[Env.getEnvName()]}>Go to Home Page</a>
              </h3>
            </section>
          </div>
          <div className='error-main--main--feedback'>
            <p>{prop.secondaryMessage || "We've logged this 404 error, but you can also provide more information." }</p>
            <p className='error-main--main--feedback--link'>
              <a
                href={feedbackURL}
                rel='noreferrer'
                target={envName === 'DEV' ? '_blank' : '_self'}>
                Tell us what went wrong
                <span className='error-main--main--feedback--arrow'>
                  <ArrowRightIcon />
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
