// @ts-nocheck
import React, { ReactNode, useEffect } from 'react';
import {
    Bullseye,
    EmptyState,
    EmptyStateBody,
    EmptyStateIcon,
    EmptyStateVariant,
    Spinner,
    Title,
} from '@patternfly/react-core';
import {
    Table,
    TableVariant,
    Tbody,
    Td,
    Th,
    Thead,
    ThProps,
    Tr,
} from '@patternfly/react-table';
import { useTableContext } from './TableContext';
import { ErrorCircleOIcon, SearchIcon } from '@patternfly/react-icons';
import { isEmpty, map, orderBy } from 'lodash';

export interface IColumn {
    title: string;
    accessor: (data: any) => string | number | ReactNode;
    extraProps?: any;
    sortable?: boolean;
    cell?: (data: any) => any;
    key?: string;
}

export interface PFTableProps {
    aria?: string;
    isLoading?: boolean;
    error?: string;
    onSelect?: (rowId, isSelected) => void;
    selectableRows?: boolean;
    emptyStateTitle?: string | React.ReactNode;
    emptyStateBody?: string | React.ReactNode;
    emptyStateComponent?: React.ReactNode;
    selectVariant?: string;
    totalItems?: number;
    //method to filter out the results based on search value
    filterCriteria?: (data, search) => boolean;
    onSOrt?: (index: string, direction: 'asc' | 'desc') => void;
    initialSortByData?: { index: number; direction: 'asc' | 'desc' };
    variant?: TableVariant | 'compact';
    isStriped?: boolean;
}

const RowPlaceHoldWrapper = ({ children }: { children: React.ReactNode }) => (
    <Tr className='row-placeholder'>
        <Td colSpan={10}>
            <Bullseye>{children}</Bullseye>
        </Td>
    </Tr>
);
const errorRow = (error) => (
    <RowPlaceHoldWrapper>
        <EmptyState variant={EmptyStateVariant.full}>
            <EmptyStateIcon icon={ErrorCircleOIcon} />
            <Title headingLevel='h5' size='md'>
                {error}
            </Title>
        </EmptyState>
    </RowPlaceHoldWrapper>
);

const emptyStateRow = (title?, body?) => (
    <RowPlaceHoldWrapper>
        {' '}
        <EmptyState variant={EmptyStateVariant.small}>
            <EmptyStateIcon icon={SearchIcon} />
            <Title headingLevel='h2' size='lg'>
                {title ? title : 'No results found'}
            </Title>
            <EmptyStateBody>{body ? body : null}</EmptyStateBody>
        </EmptyState>
    </RowPlaceHoldWrapper>
);

const loadingStateRow = (
    <RowPlaceHoldWrapper>
        <EmptyState variant={EmptyStateVariant.small}>
            <Spinner />
        </EmptyState>
    </RowPlaceHoldWrapper>
);

export const PFTableV2 = (props: PFTableProps) => {
    const {
        columns,
        rows,
        actionResolver,
        currentPage,
        perPage,
        setSelectedPage,
        searchValue,
        searchFunction,
        disableBuiltInPagination,
    } = useTableContext();

    const [sortBy, setSortBy] = React.useState(props.initialSortByData || {});
    const [dataRows, setDataRows] = React.useState(rows);
    const filterQuery = React.useRef('');

    useEffect(() => {
        if (filterQuery.current !== searchValue) {
            searchFunction(searchValue, null, null, setSelectedPage);
        }
        filterQuery.current = searchValue;
        setDataRows(rows);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows, searchValue]);

    const onSelect = (event, isSelected: boolean, rowId: number, selectAll?: boolean) => {
        let rows;
        if (selectAll) {
            rows = map(dataRows, (row) => {
                row.selected = isSelected;
                return row;
            });
        } else {
            rows = [...dataRows];
            const index = rows.findIndex((el) => el._id === rowId);
            rows[index].selected = isSelected;
        }
        setDataRows(rows);
        props.onSelect?.(rowId, isSelected);
    };

    const onSort = (_event, index, direction) => {
        setSortBy({
            index,
            direction,
        });
        if (props.onSOrt) {
            props.onSOrt(index, direction);
        } else {
            const sorter = (prop) => {
                const value = columns[index]?.accessor(prop);
                return typeof value === 'string' ? value.toLowerCase() : value;
            };
            const sortedRows = orderBy(dataRows, [sorter], [direction]);
            setDataRows(sortedRows);
        }
    };

    const getSortParams = (columnIndex: number): ThProps['sort'] => ({
        sortBy: sortBy,
        onSort: onSort,
        columnIndex,
    });

    const getTableRowsData = (rows) => {
        if (!disableBuiltInPagination) {
            rows = rows?.slice((currentPage - 1) * perPage, currentPage * perPage);
        }
        return rows.map((item, rowIndex) => {
            return (
                <Tr key={rowIndex}>
                    {props.selectableRows && (
                        <Td
                            select={{
                                rowIndex,
                                onSelect: (_event, isSelecting) =>
                                    onSelect(_event, isSelecting, item._id),
                                isSelected: item.selected,
                            }}
                        />
                    )}
                    {columns.map((col, id) => (
                        <Td key={`td-${id}`} dataLabel={col.title} {...(col.extraProps?.row || {})}>
                            {col?.cell ? col.cell(item) : col?.accessor ? col.accessor(item) : ''}
                        </Td>
                    ))}
                    {actionResolver && <Td actions={{ items: actionResolver(item, {}) }} />}
                </Tr>
            );
        });
    };

    const getTableRows = () => {
        return props.isLoading ? (
            loadingStateRow
        ) : props.error ? (
            errorRow(props.error)
        ) : isEmpty(dataRows) ? (
            props.emptyStateComponent ? (
                <RowPlaceHoldWrapper>{props.emptyStateComponent}</RowPlaceHoldWrapper>
            ) : (
                emptyStateRow(props.emptyStateTitle, props.emptyStateBody)
            )
        ) : (
            getTableRowsData(dataRows)
        );
    };

    return (
        <React.Fragment>
            <Table aria-label="Actions table" variant={props.variant} borders isStriped={props.isStriped} >
                <Thead>
                    <Tr>
                        {props.selectableRows && <Th />}
                        {columns.map((col, colIndex) => (
                            <Th
                                key={`table-head-${colIndex}`}
                                {...(col.extraProps?.column || {})}
                                sort={col.sortable && getSortParams(colIndex)}>
                                {col.title}
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>{getTableRows()}</Tbody>
            </Table>
        </React.Fragment>
    );
};
