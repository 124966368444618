export interface IActiveProductDetails {
    data: ProductList;
}
export interface ProductList {
    unfinishedProducts: IUnfinishedProducts;
    publishedProducts: IPublishedProducts;
}
export interface IUnfinishedProducts {
    error?: null;
    data?: (IProductListDetails)[] | null;
}
export interface IProductListDetails {
    _id: string;
    category?: null;
    cert_projects?: (string)[] | null;
    org_id: number;
    contacts?: (ContactsEntity)[] | null;
    descriptions?: Descriptions | null;
    faqs?: null;
    features?: null;
    functional_categories?: (string)[] | null;
    legal?: null;
    linked_resources?: (LinkedResourcesEntity)[] | null;
    logo?: Logo | null;
    name: string;
    published: boolean;
    deleted: boolean;
    quick_start_configuration?: null;
    repositories?: null;
    search_aliases?: (SearchAliasesEntity)[] | null;
    support?: Support | null;
    type: string;
    certification_badges_all?: (string)[] | null;
    certification_badges?: (string)[] | null;
    certification_level?: string | null;
    deployed_as_all?: null;
    deployed_as?: null;
    creation_date: string;
    last_update_date: string;
    target_platforms?: (string)[] | null;
    product_validations_data: IProductValidation;
    published_history: IPublishedHistory;
}
export interface Logo {
    url?: null;
}
export interface IPublishedProducts {
    error?: null;
    data?: (IProductListDetails)[] | null;
}

export interface IProductValidation {
   validations: any[];
}

interface ContactsEntity {
    type: string;
    email_address: string;
}
interface Descriptions {
    long: string;
    short: string;
}
interface LinkedResourcesEntity {
    category?: string | null;
    description?: string | null;
    title: string;
    type: string;
    url?: string | null;
}
interface SearchAliasesEntity {
    key: string;
    value: string;
}
interface Support {
    description?: string | null;
    email_address?: string | null;
    phone_number?: string | null;
    url?: string | null;
}

export interface ProductScoreResponse {
    totalItems: number,
    totalScore: number,
    totalMaxScore: number,
    items: [ICheckListItem]
}

export interface ICheckListItem {
    id: string,
    modifiedDate: Date,
    createdDate: Date,
    createdBy: string,
    modifiedBy: string,
    deleted: boolean,
    name: string,
    title: string,
    description: string,
    checklistItemActions: [ICheckListActionItems],
    status: "ERROR" | "COMPLETED" | "NOT_COMPLETED",
    visibleValidatorName: string,
    displayOrder: number,
    enabled: boolean,
    optional: boolean,
    statusProcessorName: string,
    suggestion: string,
    reasons: [
        string
    ]
}

export interface ICheckListActionItems {
    createdBy: string,
    createdDate: Date,
    displayOrder: number,
    enabled: boolean,
    id: string,
    label: string,
    link: string,
    modifiedBy: string,
    modifiedDate: Date,
    name: string,
    statusProcessorName: string,
    type: string,
    visibleValidatorName: string,
}

interface IPublishedHistory {
    history: IHistory[];
}
interface IHistory{
    date: Date;
}

export const ProductListingTypes = {
    ContainerStack: 'container stack',
    TraditionalApplication: 'traditional application',
    OpenStackInfrastructure: 'openstack infrastructure',
};

export const listingTypeOptions = [
    {
        header: "Containerized",
        value: ProductListingTypes.ContainerStack,
    },
    {
        header: "Standalone",
        value: ProductListingTypes.TraditionalApplication,
    },
    {
        header: "OpenStack",
        value: ProductListingTypes.OpenStackInfrastructure,
    },
];
