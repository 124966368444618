import { FC } from 'react';
import {
    ActionGroup,
    Bullseye,
    Button,
    Text,
    TextContent,
    TextVariants,
} from '@patternfly/react-core';
import { TNCAgreementColumn } from '../../interfaces/tnc-agreements';
import { IColumn, PFTableV2 } from '../General/Table/PFTableV2';
import { TableContextConsumer } from '../General/Table/TableContext';
import { Table } from '../General/Table';
import { TableText } from '@patternfly/react-table';
import { CheckIcon } from '@patternfly/react-icons';
import Env from '../../utils/env';
import { useQueryClient } from 'react-query';

const Step1AcceptTnC: FC<{
    allTermsAccepted: boolean;
    changePage: (page: 1 | 2 | 3) => void;
}> = ({ allTermsAccepted, changePage }) => {
    const queryClient = useQueryClient();
    const terms = queryClient.getQueryData<TNCAgreementColumn[]>(['agreements']) || [];
    const columns: IColumn[] = [
        {
            title: 'Name',
            cell: (data: TNCAgreementColumn) => (
                <TableText wrapModifier='breakWord'>{data?.name}</TableText>
            ),
            extraProps: { column: { width: 60 } },
            sortable: false,
            accessor: (data: TNCAgreementColumn) => data?.name,
            key: 'name',
        },
        {
            title: 'Required for',
            cell: (data: TNCAgreementColumn) => (
                <TableText wrapModifier='breakWord'>{data?.requiredFor}</TableText>
            ),
            extraProps: { column: { width: 30 } },
            sortable: false,
            accessor: (data: TNCAgreementColumn) => data?.requiredFor,
            key: 'required for',
        },
        {
            title: 'Accepted?',
            cell: (data: TNCAgreementColumn) =>
                data?.status ? (
                    <Bullseye>
                        <span className="pf-v5-u-screen-reader">Yes</span>
                        <CheckIcon aria-hidden={true} />
                    </Bullseye>
                ) : (
                    <span className="pf-v5-u-screen-reader">No</span>
                ),
            extraProps: { column: { width: 10 } },
            sortable: false,
            accessor: (data: TNCAgreementColumn) => data?.status.toString(),
            key: 'accepted',
        },
    ];
    return (
        <div className='wizard-main'>
            <TextContent>
                <Text component={TextVariants.h2}>
                    Accept Hardware Partner Terms and Conditions
                </Text>
                <Text className='page-description'>
                    Several terms and conditions must be accepted before you can take full advantage
                    of all aspects of your partnership with Red Hat.
                </Text>
            </TextContent>
            <Table columns={columns} rows={terms}>
                <TableContextConsumer>
                    {() => <PFTableV2 aria={'Terms and Conditions'} />}
                </TableContextConsumer>
            </Table>
            <ActionGroup className='button-group'>
                {allTermsAccepted ? (
                    <Button
                        onClick={() => {
                            changePage(2);
                        }}
                        variant='primary'>
                        Next
                    </Button>
                ) : (
                    <Button
                        onClick={() => {
                            const UGC_PORTAL_URL = Env.UGCPortals[Env.getEnvName()];
                            const redirectUrl: string = encodeURI(window.location.href);
                            window.location.href = `${UGC_PORTAL_URL}wapps/tnc/ackrequired?site=partnerConnect&event=codeOfConduct,partnerTerms,hardwareAppendix&redirect=${redirectUrl}&cancelRedirect=${redirectUrl}`;
                        }}
                        variant='primary'>
                        Accept Terms and Conditions
                    </Button>
                )}
            </ActionGroup>
        </div>
    );
};

export default Step1AcceptTnC;
