import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import Env from "./utils/env"
import * as Sentry from "@sentry/react";

if (Env.getEnvName() === Env.EnvNames.PROD) {
    Sentry.init({
        environment: `production`,
        ...(process.env.REACT_APP_SPA_VERSION && {
            release: `spco-001-rhc-partner-admin@` + process.env.REACT_APP_SPA_VERSION
        }),
        dsn: `https://0c75a5f3dd5efd9f1175d8253ba696d4@o490301.ingest.sentry.io/4506780302770176`
    });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
