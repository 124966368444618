import { FC } from 'react';
import { Button,Stack,StackItem,Text,TextContent } from '@patternfly/react-core';
import { DashboardCard } from '../shared/DashboardCard';
import SupportIcon from '../../../assets/Icon-Red_Hat-Support-A-Red-RGB.png';
import { useQueryClient } from 'react-query';
import { IPartnershipStatus } from '../../../constants/apiconstants-pfs';
import HalfGridCardWrapper from '../shared/HalfGridCardWrapper'

export const SupportCard: FC = () => {
    const queryClient = useQueryClient();
    const partnershipStatus = queryClient.getQueryState(['partnershipStatus'])
        ?.data as IPartnershipStatus;
    const SupportCardBody: FC = () => {
        return (
            <Stack hasGutter>
                {partnershipStatus?.businessPartner && (
                    <>
                        <StackItem>
                            <Button
                                variant="link"
                                isInline
                                component="a"
                                href="https://partners.connect.redhat.com/s/createrecord/NewCase">
                                Sales and opportunities
                            </Button>
                            <TextContent>
                                <Text component="p">
                                    Currently limited to resellers, distributors and sell-with
                                    partners
                                </Text>
                            </TextContent>
                        </StackItem>
                        <StackItem>
                            <Button
                                variant="link"
                                isInline
                                component="a"
                                href="https://partnercenter.redhat.com/500/o">
                                Business accounts
                            </Button>
                            <TextContent>
                                <Text component="p">
                                    Support for these partner types: Global Systems Integrators
                                    (GSI),
                                    Embedded, Training and Certification, and Cloud and Service
                                    Providers.
                                </Text>
                            </TextContent>
                        </StackItem>
                    </>
                )}
                {partnershipStatus?.techPartner && (
                    <StackItem>
                        <Button
                            variant="link"
                            isInline
                            component="a"
                            href="https://connect.redhat.com/support/technology-partner/#/case/new">
                            Technical partners
                        </Button>
                        <TextContent>
                            <Text component="p">
                                Primarily support for software and hardware partners or any partner
                                building
                                products on or for Red hat platforms
                            </Text>
                        </TextContent>
                    </StackItem>
                )}
            </Stack>
        );
    };

    if (partnershipStatus?.techPartner || partnershipStatus?.businessPartner) {
        return (
            <HalfGridCardWrapper>
                <DashboardCard
                    icon={SupportIcon}
                    title="Support"
                    openNewTab={false}
                    link={null}
                    description={null}
                    label={null}
                    isLoading={false}
                    isSelectable={false}
                    cardBody={<SupportCardBody />}
                    variant="inline"
                    hasLabelIcon={false}
                />
            </HalfGridCardWrapper>
        );
    }
    return null
};
