import React from "react"
import {
    DescriptionList,DescriptionListDescription,
    DescriptionListGroup,
    DescriptionListTerm,
    StackItem
} from "@patternfly/react-core"
import Env from "../../utils/env"

export const AdditionalLinks: React.FC = () => (<StackItem>
    <DescriptionList>
        <DescriptionListGroup>
            <DescriptionListTerm>Additional links</DescriptionListTerm>
            <DescriptionListDescription>
                <a
                    data-analytics-linktype={'cta'}
                    data-analytics-text={'Partner program guides and resource'}
                    data-analytics-category={'Additional links|Partner program guides and resources'}
                    rel={'noreferrer'}
                    href={'https://content.redhat.com/us/en/sales-enablement/partner-sales/partner-engagement-experience.html'}>
                    Partner program guides and resources
                </a>
            </DescriptionListDescription>
            <DescriptionListDescription>
                <a
                    target={'_blank'}
                    data-analytics-linktype={'cta'}
                    data-analytics-text={`Red Hat Partner Connect`}
                    data-analytics-category={`Additional links|Red Hat Partner Connect`}
                    rel={'noreferrer'}
                    href={`${Env.ConnectPortalUrls[Env.getEnvName()]}`}>
                    Partner connect homepage
                </a>
            </DescriptionListDescription>
            <DescriptionListDescription>
                <a
                    target={'_blank'}
                    rel={'noreferrer'}
                    data-analytics-linktype={'cta'}
                    data-analytics-text={`Red Hat Customer Portal`}
                    data-analytics-category={`Additional links|Red Hat Customer Portal`}
                    href={`${Env.AccessRedhatPortals[Env.getEnvName()]}`}>
                    Customer portal
                </a>
            </DescriptionListDescription>
            <DescriptionListDescription>
                <a
                    target={'_blank'}
                    rel={'noreferrer'}
                    data-analytics-linktype={'cta'}
                    data-analytics-text={`Red Hat Developer`}
                    data-analytics-category={`Additional links|Red Hat Developer`}
                    href={`${
                        Env.DevelopersRedhatPortals[Env.getEnvName()]
                    }`}>
                    Developers
                </a>
            </DescriptionListDescription>
            <DescriptionListDescription>
                <a
                    target={'_blank'}
                    rel={'noreferrer'}
                    data-analytics-linktype={'cta'}
                    data-analytics-text={`Red Hat Ecosystem Catalog`}
                    data-analytics-category={`Additional links|Red Hat Ecosystem Catalog`}
                    href={`${Env.CatalogPortals[Env.getEnvName()]}`}>
                    Red Hat Ecosystem Catalog
                </a>
            </DescriptionListDescription>
        </DescriptionListGroup>
    </DescriptionList>
</StackItem>);
