import { IProductListDetails, ProductListingTypes } from '../constants/products'
import containerisedAppImg from '../assets/containerised-app.svg'
import standaloneApp from '../assets/standalone-app.svg'
import openstackImg from '../assets/openstack.svg'
import { ReactNode } from 'react'
import { CardHeader,Flex,FlexItem,Title } from '@patternfly/react-core'
import softwareCodeRed from '../assets/software-code-red.svg'

const MAX_SCORE_LIST = 10;

export const getTypeNameWithIcon = (type: string) => {
    switch (type) {
        case ProductListingTypes.ContainerStack:
            return <img  src={containerisedAppImg} alt={"Container-Application-icon"}/>
        case ProductListingTypes.TraditionalApplication:
            return <img src={standaloneApp} alt={"Standalone-Application-icon"}/>
        case ProductListingTypes.OpenStackInfrastructure:
            return <img src={openstackImg} alt={"Openstack-infrastructure-icon"}/>
        default:
            return null;
    }
}

export const calculateLocalProductScore = (productDetails: IProductListDetails) => {
    const MAX_LOCAL_SCORE = MAX_SCORE_LIST + 1.1;
    let scoreList = 0;
    let scoreCert = 0;
    // Calculating product scores using items
    // Product Name
    if (productDetails?.name) ++scoreList;

    // Brief Description
    if (productDetails?.descriptions?.short) ++scoreList;

    // Long Description
    if (productDetails?.descriptions?.long) ++scoreList;

    // Marketing contact email address
    if ((productDetails?.contacts ?? []).length > 0) ++scoreList;

    // Linked Resources (max: 3 score)
    scoreList = scoreList + Math.min(3, (productDetails?.linked_resources?.length || 0));

    // Support website URL
    if (productDetails?.support?.url) ++scoreList;

    // SEO Category
    if ((productDetails?.functional_categories ?? []).length > 0) ++scoreList;

    // SEO Search Aliases
    if ((productDetails?.search_aliases ?? []).length > 0) ++scoreList;


    // Partner validation check
    if (productDetails?.product_validations_data?.validations?.length > 0) ++scoreCert;

    // Checking if any cert projects is attached
    else if ((productDetails?.cert_projects ?? []).length > 0) ++scoreCert;

    return {
        productScorePercentage: Math.floor(((scoreList+scoreCert)/ MAX_LOCAL_SCORE) * 100),
        scoreCert,
        scoreList
    }

}

export const getProductPublishStatusText = ( scoreCert: number ,scoreList: number ): ReactNode => {

    if ((scoreCert < 1)  && (scoreList < MAX_SCORE_LIST) ) {
        return "Needs cert & listing info";
    } else if (scoreList < MAX_SCORE_LIST) {
        return "Needs listing info";
    } else if (scoreCert < 1) {
        return "Needs cert info";
    } else {
        return "Ready to publish";
    }
};

export const ActiveProductsHeader = () => {
    return (
        <CardHeader>
            <Flex direction={{ default: 'row' }}
                  alignItems={{ default: 'alignItemsCenter' }}>
                <FlexItem>
                    <img src={softwareCodeRed} aria-hidden={true} alt={'logo'}
                         className="authenticated-dashboard__img" />
                </FlexItem>
                <FlexItem spacer={{ default: 'spacerSm' }}>
                    <Title headingLevel="h2">Software products</Title>
                </FlexItem>
            </Flex>
        </CardHeader>
    )
}
