import React,{ useEffect } from 'react';
import { RHCAuthProvider, useRHCAuthContext } from '@rhc-shared-components/rhc-sso';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AlertComponent, AlertProvider } from '@rhc-shared-components/alert-component';
import { BrowserRouter,Redirect,Route,Switch,useLocation } from 'react-router-dom';
import Env,{ getUserInfoFromJWT } from './utils/env';
import './App.scss';
import { DashBoardWrapper } from './components/PartnerDashboard/DashBoardWrapper';
import HWPartnerOnboarding from './components/HWPartnerOnboarding/HWPartnerOnboarding';
import * as Sentry from '@sentry/react';
import FourOhFour from './shared/FourOhFour/FourOhFour';
import { checkAndSendAnalytics } from "@rhc-shared-components/rhc-eddl";
import { PartnerSubscriptions } from './components/PartnerSubscriptions/PartnerSubscriptions'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
            refetchOnWindowFocus: false,
        },
    },
});

const AuthenticatedApp: React.FC = () => {
    const { isAuthenticated } = useRHCAuthContext(); // Auth status
    if (isAuthenticated && Env.getEnvName() === Env.EnvNames.PROD) {
        Sentry.setUser(getUserInfoFromJWT());
    }
    const { pathname } = useLocation();
    useEffect(() => {
        if (isAuthenticated) {
            checkAndSendAnalytics({spaName: 'partner-admin'});
        }
    }, [pathname, isAuthenticated]);
    return (
        <>
            <AlertComponent />
            {isAuthenticated ? (
                <AlertProvider>
                    <Switch>
                        <Redirect from='/' to={Env.routePrefix + '/dashboard'} exact />
                        <Redirect from='/partner-admin' to={Env.routePrefix + '/dashboard'} exact />
                        <Route
                            exact={true}
                            path={[
                                `${Env.routePrefix}/dashboard`,
                                `${Env.routePrefix}/not-a-partner`,
                            ]}
                            component={DashBoardWrapper}
                        />
                        <Route
                            exact={true}
                            path={Env.routePrefix + '/hardware-partner-onboarding'}
                            component={HWPartnerOnboarding}
                        />
                        <Route
                            exact={true}
                            path={Env.routePrefix + '/partner-subscriptions'}
                            component={PartnerSubscriptions}
                        />
                        <Route component={FourOhFour} />
                    </Switch>
                </AlertProvider>
            ) : (
                <></>
            )}
        </>
    );
};
const App: React.FC = () => (
    <div className='app' role={"main"}>
        <QueryClientProvider client={queryClient}>
            <RHCAuthProvider scope={"api.partner_connect api.graphql"}>
                <BrowserRouter>
                    <AuthenticatedApp />
                </BrowserRouter>
            </RHCAuthProvider>
        </QueryClientProvider>
    </div>
);
export default App;
