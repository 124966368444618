/* eslint-disable */
import React from 'react';
import {
    IActionsResolver,
    IRowData,
    IExtraData,
    IAreActionsDisabled,
} from '@patternfly/react-table';

interface ITableContext {
    columns: any[];
    rows: any;
    totalItems: number;
    actionResolver: IActionsResolver;
    areActionsDisabled: IAreActionsDisabled;
    currentPage: number;
    perPage: number;
    searchValue: string;
    setSearchValue: React.Dispatch<React.SetStateAction<string>>;
    setSelectedPage: React.Dispatch<React.SetStateAction<number>>;
    setPerPage: React.Dispatch<React.SetStateAction<number>>;
    selectionFilterValue: string;
    setSelectionFilter: React.Dispatch<React.SetStateAction<string>>;
    searchFunction?: (
        query: string,
        filterType?: string,
        filterValue?: string | number,
        setSelectedPage?: React.Dispatch<React.SetStateAction<number>>
    ) => any;
    selectItemsFunction?: (
        filter: string,
        currentPage: number,
        setSelectedPage: React.Dispatch<React.SetStateAction<number>>,
        perPage: number
    ) => any;
    disableBuiltInPagination?: boolean;
}

const intialTableContext: ITableContext = {
    columns: [],
    rows: [],
    totalItems: 0,
    actionResolver: (rowData: IRowData, extraData: IExtraData) => [],
    areActionsDisabled: (rowData: IRowData, extraData: IExtraData) => false,
    currentPage: 1,
    perPage: 10,
    selectionFilterValue: '',
    searchValue: '',
    setSearchValue: () => {},
    setSelectedPage: () => {},
    setPerPage: () => {},
    setSelectionFilter: () => {},
};

export const TableContext = React.createContext<ITableContext>(intialTableContext);

export function useTableContext() {
    const context = React.useContext(TableContext);
    if (context === undefined) {
        throw new Error(`Please provide`);
    }
    return context;
}

export const TableContextConsumer = TableContext.Consumer;
