import { query } from 'gql-query-builder';
import { IPaginationRequest } from "../constants/pagination";
import Env from "../utils/env";
import {postUri } from '../utils/fetch';
import { IActiveProductDetails } from '../constants/products';

export function getProductNameByOrgIdQuery(
    orgID: string,
    pagination: IPaginationRequest = {
        page: 0,
        page_size: 3,
    },
    abortSignal?: AbortSignal
): Promise<IActiveProductDetails> {
    const publishedQuery = {
        operation: {
            name: 'find_vendor_product_listings_by_org_id',
            alias: 'publishedProducts',
        },
        variables: {
            orgId: { name: 'org_id',value: Number(orgID),required: true,type: 'Int' },
            sortBy: {
                name: 'sort_by',
                value: { field: 'last_update_date',order: 'DESC' },
                required: false,
                type: '[SortBy]',
            },
            page: { value: pagination.page },
            page_size: { value: pagination.page_size },
            publishedFilter: {
                name: 'filter',
                value: {
                    and: [
                        { published: { eq: true } },
                        { deleted: { ne: true } },
                    ],
                },
                required: true,
                type: 'ProductListingFilter',
            },
        },
        fields: [
            {
                error: ['status','detail'],
            },
            {
                data: [
                    '_id',
                    'category',
                    'cert_projects',
                    'org_id',
                    {
                        published_history:[
                            {
                                history:[
                                    'date'
                                ]
                            }
                        ]

                    },
                    {
                        contacts: [
                            'type',
                            'email_address'
                        ]
                    },
                    {
                        descriptions: [
                            'long',
                            'short'
                        ]
                    },
                    {
                        faqs: [
                            'answer',
                            'question'
                        ]
                    },
                    {
                        features: [
                            'description',
                            'title'
                        ]
                    },
                    'functional_categories',
                    {
                        legal: [
                            'description',
                            'license_agreement_url',
                            'privacy_policy_url'
                        ]
                    },
                    {
                        linked_resources: [
                            'description',
                            'title',
                            'type',
                            'url'
                        ]
                    },
                    {
                        logo: [
                            'url'
                        ]
                    },
                    'name',
                    'published',
                    'deleted',
                    {
                        quick_start_configuration: [
                            'instructions',
                            'default_deploy_instructions'
                        ]
                    },
                    'repositories',
                    {
                        search_aliases: [
                            'key',
                            'value'
                        ]
                    },
                    {
                        support: [
                            'description',
                            'email_address',
                            'phone_number',
                            'url'
                        ]
                    },
                    {
                        product_validations_data:[
                            {
                                validations:[
                                    "_id"
                                ]
                            }
                        ]
                    },
                    'type',
                    'certification_badges_all',
                    'certification_badges',
                    'certification_level',
                    'deployed_as_all',
                    'deployed_as',
                    'creation_date',
                    'last_update_date',
                    'target_platforms',
                ]
            },
        ],
    };

    const unpublishedQuery = {
        operation: {
            name: 'find_vendor_product_listings_by_org_id',
            alias: 'unfinishedProducts',
        },
        variables: {
            orgId: { name: 'org_id',value: Number(orgID),required: true,type: 'Int' },
            sortBy: {
                name: 'sort_by',
                value: { field: 'last_update_date',order: 'DESC' },
                required: false,
                type: '[SortBy]',
            },
            page: { value: pagination.page },
            page_size: { value: pagination.page_size },
            unPublishedFilter: {
                name: 'filter',
                value: {
                    and: [
                        { published: { ne: true } },
                        { deleted: { ne: true } },
                    ],
                },
                required: true,
                type: 'ProductListingFilter',
            },
        },
        fields: [
            {
                error: ['status','detail'],
            },
            {
                data: [
                    '_id',
                    'category',
                    'cert_projects',
                    'org_id',
                    {
                        contacts: [
                            'type',
                            'email_address'
                        ]
                    },
                    {
                        descriptions: [
                            'long',
                            'short'
                        ]
                    },
                    {
                        faqs: [
                            'answer',
                            'question'
                        ]
                    },
                    {
                        features: [
                            'description',
                            'title'
                        ]
                    },
                    'functional_categories',
                    {
                        legal: [
                            'description',
                            'license_agreement_url',
                            'privacy_policy_url'
                        ]
                    },
                    {
                        linked_resources: [
                            'description',
                            'title',
                            'type',
                            'url'
                        ]
                    },
                    {
                        logo: [
                            'url'
                        ]
                    },
                    'name',
                    'published',
                    'deleted',
                    {
                        quick_start_configuration: [
                            'instructions',
                            'default_deploy_instructions'
                        ]
                    },
                    'repositories',
                    {
                        search_aliases: [
                            'key',
                            'value'
                        ]
                    },
                    {
                        support: [
                            'description',
                            'email_address',
                            'phone_number',
                            'url'
                        ]
                    },
                    'type',
                    'certification_badges_all',
                    'certification_badges',
                    'certification_level',
                    'deployed_as_all',
                    'deployed_as',
                    'creation_date',
                    'last_update_date',
                    'target_platforms',
                    {
                        product_validations_data:[
                            {
                                validations:[
                                    "_id"
                                ]
                            }
                        ]
                    }
                ]
            },
        ],
    }

    const additionalParams: RequestInit = {};
    if (abortSignal) {
        Object.assign(additionalParams, {'signal': abortSignal})
    }

    const productListQuery = query([unpublishedQuery,publishedQuery]);

    return postUri(Env.pyxisGraphqlAPIURL, productListQuery, additionalParams);
}

