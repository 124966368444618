import { query } from 'gql-query-builder';
import { postUri } from '../utils/fetch'
import Env from "../utils/env"
import {
    ISuperGraphResponse,
} from '../constants/apiconstants-supergraph'

export function getSubscriptionListQuery(
    page:any,
    filterByStatus:string,
): Promise<ISuperGraphResponse> {
    const subscriptionListQuery = query({
            operation:"me",
           fields: [
            {
                subscriptionInfo:[
                    {
                        operation: "subscriptions",
                        variables: {
                            status: { type: 'RHSubscriptionsStatusFilterInput', value: filterByStatus, required: false},
                            page: { type: 'Int', value: page, required: false },
                            skus: { type: '[String!]', value: [ 'SVCMW02039', 'SVCMW02049', 'SVCMW02049A','SVCMW02040', 'MW02049', 'MW02040', 'MW02039'], required: false },
                        },
                        fields: [
                            {
                                edges: [
                                    {
                                        node: [
                                            'name',
                                            'createdDate',
                                            'endDate',
                                            'subscriptionNumber',
                                            'registrationNumber',
                                            'quantity',
                                            'lastUpdateDate',
                                            {
                                                skuProducts: [
                                                    'id',
                                                    'status',
                                                    {
                                                        skuProduct: [
                                                            'sku',
                                                            'name',
                                                            'description',
                                                            'serviceTerm',
                                                            'serviceType'
                                                        ]
                                                    },
                                                    'activeStartDate',
                                                    'activeEndDate',
                                                    'parentSubscribedProductId'
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
        },
    )
    const additionalParams: RequestInit = {
        headers: {
            'apollographql-client-name': 'rhc-partner-admin',
            'apollographql-client-version': process.env.REACT_APP_SPA_VERSION || 'dev',
        }
    };
    return postUri(Env.superGraphQl,subscriptionListQuery,additionalParams);
}
