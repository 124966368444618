import { FC } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { IContactListResponse, IErrorType } from '../../constants/apiconstants-hydra';
import { getAccountContacts } from '../../api/hydra';
import { IPartnerInfo } from '../../constants/apiconstants-pfs';
import { IPageState } from '@rhc-shared-components/table-toolbar/dist/models/GeneralModels';
import {
    Card,
    CardBody,
    DescriptionList,
    DescriptionListDescription,
    DescriptionListGroup,
    DescriptionListTerm,
    Divider,
    Skeleton,
    Stack,
    StackItem,
    Text,
    TextContent,
    TextVariants,
} from '@patternfly/react-core';
import { AdditionalLinks } from "./shared"

export const CompanyInfoCard: FC = () => {
    const queryClient = useQueryClient();
    const partnerInformationDetails = queryClient.getQueryState(['partnerInfo'])
        ?.data as IPartnerInfo;
    const loadingPartnerInfo = queryClient.getQueryState(['partnerInfo'])?.isFetching;
    const { data: accountContactsList, isFetching } = useQuery<IContactListResponse, IErrorType>(
        ['accountContactsList'],
        async ({ signal }) => {
            const initialPaginationState: IPageState = {
                page: 1,
                perPage: 2,
            };
            const extraParam = {
                includeOrgAdmins: true,
                includeDeactivatedMembers: false,
            };
            return await getAccountContacts(initialPaginationState, extraParam, signal);
        },
        {
            enabled:
                !loadingPartnerInfo &&
                partnerInformationDetails?.accountMembershipDetail?.techPartnerOnboardingStatus,
        }
    );
    // Rendering skeleton for fetching
    if (isFetching || loadingPartnerInfo) {
        return (
            <Card isLarge>
                <CardBody>
                    <Stack hasGutter>
                        <StackItem>
                            <TextContent>
                                <Text component={TextVariants.h1}>Company information</Text>
                            </TextContent>
                        </StackItem>
                        <StackItem>
                            <DescriptionList>
                                <DescriptionListGroup>
                                    <DescriptionListTerm> Company name</DescriptionListTerm>
                                    <DescriptionListDescription>
                                        <Skeleton />
                                    </DescriptionListDescription>
                                </DescriptionListGroup>
                                <DescriptionListGroup>
                                    <DescriptionListTerm>Administrators</DescriptionListTerm>
                                    <DescriptionListDescription>
                                        <Skeleton />
                                    </DescriptionListDescription>
                                    <DescriptionListDescription>
                                        <Skeleton />
                                    </DescriptionListDescription>
                                </DescriptionListGroup>
                            </DescriptionList>
                        </StackItem>
                        <Divider />
                        <AdditionalLinks />
                    </Stack>
                </CardBody>
            </Card>
        );
    }
    return (
        <Card isLarge>
            <CardBody>
                <Stack hasGutter>
                    <StackItem>
                        <TextContent>
                            <Text component={TextVariants.h1}>Company information</Text>
                        </TextContent>
                    </StackItem>
                    <StackItem>
                        <DescriptionList>
                            <DescriptionListGroup>
                                <DescriptionListTerm> Company name</DescriptionListTerm>
                                <DescriptionListDescription>
                                    {partnerInformationDetails?.accountName}
                                </DescriptionListDescription>
                            </DescriptionListGroup>
                            {partnerInformationDetails?.accountMembershipDetail
                                ?.techPartnerOnboardingStatus && (
                                <DescriptionListGroup>
                                    <DescriptionListTerm>Administrators</DescriptionListTerm>
                                    {accountContactsList?.items?.map((elm, index) => {
                                        return (
                                            <DescriptionListDescription key={index}>
                                                <a
                                                    data-analytics-track-by-analytics-manager="false"
                                                    href={`mailto:${elm?.emailAddress}`}
                                                    target='_blank'
                                                    rel='noreferrer'>
                                                    {`${elm.firstName} ${elm.lastName}`}
                                                </a>
                                            </DescriptionListDescription>
                                        );
                                    })}
                                </DescriptionListGroup>
                            )}
                        </DescriptionList>
                    </StackItem>
                    <Divider />
                    <AdditionalLinks />
                </Stack>
            </CardBody>
        </Card>
    );
};
