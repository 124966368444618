import {
    Card,
    CardBody,CardFooter,
    Flex,
    Grid,
    GridItem,
    Skeleton,
    Stack,
    StackItem,
} from '@patternfly/react-core';
import { useQuery } from 'react-query';
import { getOrgID } from '../../../../utils/fetch';
import { IPaginationRequest } from '../../../../constants/pagination';
import { IActiveProductDetails } from '../../../../constants/products';
import { getProductNameByOrgIdQuery } from '../../../../api/pyxis';
import CertifySoftwareCard from './CertifySoftwareCard';
import ActiveProductsCard from './ActiveProductsCard';
import { ActiveProductsHeader } from '../../../../utils/productListUtils';
import { FC } from "react"

const SkeletonActiveProductsCard = () => {
    return (
        <Card isFullHeight>
            <ActiveProductsHeader />
            <CardBody>
                <Grid hasGutter>
                    <GridItem span={3}>
                        <Stack hasGutter>
                            <StackItem>
                                <Skeleton />
                            </StackItem>
                            <StackItem>
                                <Skeleton />
                            </StackItem>
                        </Stack>
                    </GridItem>
                    <GridItem span={9}>
                        <Stack hasGutter>
                            <StackItem>
                                <Skeleton width={"31%"} />
                            </StackItem>
                            <StackItem>
                                <Flex style={{height: "12rem"}} flex={{default: "flex_3"}}>
                                    <Skeleton height={"100%"} width={"31%"} />
                                    <Skeleton height={"100%"} width={"31%"} />
                                    <Skeleton height={"100%"} width={"31%"} />
                                </Flex>
                            </StackItem>
                        </Stack>
                    </GridItem>
                </Grid>
            </CardBody>
            <CardFooter>
                <Flex direction={{ default: 'row' }} flex={{ default: 'flex_2' }}>
                        <Skeleton width={'15%'} />
                        <Skeleton width={'15%'} />
                </Flex>
            </CardFooter>
        </Card>
    )
}

const ProductsCard = () => {
    const orgId = getOrgID()
    const PaginationState: IPaginationRequest = {
        page: 0,
        page_size: 3,
    };
    const { data ,isLoading } = useQuery<IActiveProductDetails>('productList',async ({ signal }) =>
        await getProductNameByOrgIdQuery(orgId,PaginationState,signal)
    );

    if (isLoading) {
        return (
            <GridItem span={12} style={{
                minHeight: "28em"
            }}>
                <SkeletonActiveProductsCard></SkeletonActiveProductsCard>
            </GridItem>
        );
    }
    if (data?.data?.unfinishedProducts?.data?.length === 0 && data?.data?.publishedProducts?.data?.length === 0) {
        return <CertifySoftwareCard />
    }
    return (
        <GridItem span={12} style={{
            minHeight: "28em"
        }}>
            <ActiveProductsCard
                activeKey={data?.data?.unfinishedProducts?.data?.length ? 0 : 1}
            />
        </GridItem>
    );
}

export default ProductsCard;
