import './FourOhFour.scss';
import Env from '../../utils/env';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@patternfly/react-icons';

const FourOhFour = () => {
    const envName = Env.getEnvName();
    const feedbackURL = `${Env.ConnectPortalUrls[envName]}contact`;

    return (
        <div className='four-oh-four-container'>
            <div className='four-oh-four'>
                <div className='four-oh-four--hero-gradient'>
                    <div className='four-oh-four-masthead'>
                        <div className='four-oh-four-masthead--content'>
                            <h1 className='four-oh-four-masthead--content--header'>Not found.</h1>
                            <p className='four-oh-four-masthead--content--para'>
                                The page you&apos;re looking for may have been moved or deleted.
                            </p>
                            <p className='four-oh-four-masthead--content--para'>
                                {' '}
                                The good news is that we can help.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='four-oh-four--relative-position--cards four-oh-four--main'>
                    <h2 className='four-oh-four--main--header'>Try one of these instead</h2>
                    <div className='four-oh-four--main--link-list'>
                        <section>
                            <h3>
                                <Link to={Env.routePrefix + '/dashboard'}>Partner Dashboard</Link>
                            </h3>
                        </section>
                    </div>
                    <div className='four-oh-four--main--feedback'>
                        <p>
                            We&apos;ve logged this 404 error, but you can also provide more
                            information.{' '}
                        </p>
                        <p className='four-oh-four--main--feedback--link'>
                            <a
                                href={feedbackURL}
                                rel='noreferrer'
                                target={envName === 'DEV' ? '_blank' : '_self'}>
                                Tell us what went wrong
                                <span className='four-oh-four--main--feedback--arrow'>
                                    <ArrowRightIcon />
                                </span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FourOhFour;
