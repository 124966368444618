import { FC } from 'react';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardTitle,
    TextContent,
    Text,
    TextVariants,
    GridItem,
    Stack,
    StackItem,
    Grid,
    Title,
} from '@patternfly/react-core';
import { ArrowRightIcon } from '@patternfly/react-icons';
import {
    JoinExistingPartnerCompanyUrl,
    PartnerBenefitsUrl,
    PartnerProgramsOfferUrl,
} from '../../constants/DashBoardUrls';
import './UnauthenticatedDashboard.scss';
import { AdditionalLinks } from './shared'

export const UnauthenticatedDashboard: FC = () => {
    return (
        <Grid hasGutter span={12} className="unauthenticated-dashboard">
            <GridItem sm={12} md={8} lg={9}>
                <Card isFullHeight isLarge>
                    <CardTitle>
                        <Title headingLevel="h1">Red Hat partner program</Title>
                    </CardTitle>
                    <CardBody>
                        <Stack hasGutter>
                            <StackItem>
                                <Alert
                                    variant="warning"
                                    isInline
                                    title="Your user account is not part of a Red Hat partner company"
                                    ouiaId="WarningAlert">
                                    If you want to access the Partner portal, you will need to join
                                    an existing partner company or become a Red Hat partner.
                                </Alert>
                            </StackItem>
                            <StackItem>
                                <TextContent>
                                    <Text component={TextVariants.h3}>
                                        Join an existing partner company
                                    </Text>
                                    <Text component={TextVariants.p}>
                                        If you are trying to access partner resources and cannot, it
                                        is possible you have created a user account that is not
                                        linked to a partner company account and therefore do not
                                        have access.
                                    </Text>
                                </TextContent>
                                <Stack
                                    hasGutter={true}
                                    className="unauthenticated-dashboard__main_links">
                                    <StackItem>
                                        <Button
                                            variant="primary"
                                            data-analytics-linktype="cta"
                                            data-analytics-text="Join an existing partner company"
                                            data-analytics-category="Join an existing partner company"
                                            component={'a'}
                                            target={'_blank'}
                                            isInline
                                            icon={<ArrowRightIcon />}
                                            iconPosition={'right'}
                                            href={JoinExistingPartnerCompanyUrl}>
                                            Join an existing partner company
                                        </Button>
                                    </StackItem>
                                </Stack>
                            </StackItem>
                            <StackItem>
                                <TextContent>
                                    <Text component={TextVariants.h3}>
                                        Become a Red Hat Partner
                                    </Text>
                                    <Text component={TextVariants.p}>
                                        As a Red Hat partner, you have access to benefits designed
                                        to help you succeed in your business and with your
                                        customers.
                                    </Text>
                                </TextContent>
                                <Stack
                                    hasGutter={true}
                                    className="unauthenticated-dashboard__main_links">
                                    <StackItem>
                                        <Button
                                            component={'a'}
                                            data-analytics-linktype="cta"
                                            data-analytics-text="Learn about the benefits of partnership"
                                            data-analytics-category="Become a Red Hat Partner|Learn about the benefits of partnership"
                                            variant="link"
                                            target={'_blank'}
                                            isInline
                                            href={PartnerBenefitsUrl}
                                            icon={<ArrowRightIcon />}
                                            iconPosition={'right'}>
                                            Learn about the benefits of partnership
                                        </Button>{' '}
                                    </StackItem>
                                    <StackItem>
                                        <Button
                                            variant={'link'}
                                            component={'a'}
                                            data-analytics-linktype="cta"
                                            data-analytics-text="View Red Hat's partner program offerings"
                                            data-analytics-category="Become a Red Hat Partner|View Red Hat's partner program offerings"
                                            target={'_blank'}
                                            isInline
                                            icon={<ArrowRightIcon />}
                                            iconPosition={'right'}
                                            href={PartnerProgramsOfferUrl}>
                                            View Red Hat&apos;s partner program offerings
                                        </Button>{' '}
                                    </StackItem>
                                </Stack>
                            </StackItem>
                        </Stack>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem sm={12} md={4} lg={3}>
                <Card isFullHeight isLarge>
                    <CardBody>
                        <Stack hasGutter>
                            <AdditionalLinks />
                        </Stack>
                    </CardBody>
                </Card>
            </GridItem>
        </Grid>
    );
};
