// @ts-nocheck
import includes from 'lodash/includes';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import Uri from 'jsuri';
import Env, {MONGOID_COOKIE, SAMPLE_JWT_TOKEN } from './env';

// interfaces definitions
interface IError {
  message: string;
  stack?: string;
  extra?: {
    url: string;
    params: object;
  };
}

export interface IAdditionalOptions {
  dataType?: string;
  externalUrl?: boolean;
  useCookieOnly?: boolean;
}

export const defaultAdditionalOptions: IAdditionalOptions = {
  dataType: '',
  externalUrl: false,
  useCookieOnly: false,
};

const baseParams: RequestInit = {
  // adding this because according to MDN, fetch will set credentials to same-origin by default,
  // which will let to inclusion of cookies into the request
  // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  credentials: 'omit',
  headers: {
    Accept: 'application/json',
  },
  signal: null,
};

// helper functions
const errorHandler = (response: Response) => {
  return response.text().then((body) => {
    if (body == null || body === '') {
      const error = new Error(body);
      Object.assign(error, { status: response.status }, { statusText: response.statusText });
      throw error;
    }
    let parsedError;
    try {
      parsedError = JSON.parse(body);
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e);
    }
    if (parsedError) {
      const error = new Error((parsedError && parsedError.message) || body);
      Object.assign(
        error,
        parsedError,
        { isApiError: true },
        { status: response.status },
        { statusText: response.statusText }
      );
      throw error;
    } else {
      const error = new Error(body);
      Object.assign(error, { status: response.status }, { statusText: response.statusText });
      throw error;
    }
  });
};

const readAndDecodeTokenFromCookie = (): any => {
  const cookie = window?.sessionjs?.token;
  const token =
    cookie && cookie !== undefined && cookie !== 'undefined' ? cookie : SAMPLE_JWT_TOKEN;
  return jwt_decode(token);
};

const responseHandler = <T>(response: Response, dataType?: string): Promise<T | string> => {
  try {
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (dataType !== 'text' && contentType && includes(contentType, 'json')) {
        return response
          .json()
          .then((j) => Promise.resolve(j))
          .catch(() => Promise.resolve({}));
      } else if (dataType === 'text' || (contentType && includes(contentType, 'text'))) {
        return response.text();
      } else {
        // Defaulting to text if content type cannot be determined
        // https://github.com/github/fetch/issues/268#issuecomment-176544728
        return response
          .text()
          .then((j) => Promise.resolve(j ? JSON.parse(j) : {}))
          .catch(() => Promise.resolve({}));
      }
    } else {
      return errorHandler(response);
    }
  } catch {
    return errorHandler(response);
  }
};

const isError = (error: IError): boolean => {
  return error && error.message != null;
};

const processCaughtError = (uri: string, params: RequestInit, error: IError) => {
  // eslint-disable-next-line no-useless-catch
  try {
    if (isError(error)) {
      error.extra = {
        url: uri.toString(),
        params,
      };
    }
  } catch (e) {
    throw e;
  }
};

const fetchURIWithParams = async <T>(uri, params: RequestInit, dataType?: string): Promise<T> => {
  try {
    const response = await fetch(uri.toString(), params);
    return responseHandler<T>(response, dataType) as Promise<T>;
  } catch (error) {
    processCaughtError(uri, params, error);
    return Promise.reject(error);
  }
};

const callFetchAndHandleJwt = async <T>(
  uri,
  params: RequestInit,
  additionalOptions?: IAdditionalOptions
): Promise<T> => {
  // eslint-disable-next-line
  const isIE11 = !(window as any).ActiveXObject && 'ActiveXObject' in window;
  if (!additionalOptions.externalUrl) {
    if (!isIE11 && getAuthToken() && !additionalOptions.useCookieOnly) {
      params.headers['Authorization'] = `Bearer ${getAuthToken()}`;
    } else {
      // In IE servers give cached responses thus adding cache burst param
      isIE11 && uri.addQueryParam('cache-burst-param', new Date().getTime());
      // tslint:disable-next-line:no-console
      console.info(
        `Didn't add Auth headers ${
          isIE11
            ? 'in IE11 browser'
            : additionalOptions.useCookieOnly
            ? 'explicitly mentioned to use cookie'
            : 'as token was missing'
        }, relying on auth cookie being passed`
      );
    }
  }
  return fetchURIWithParams<T>(uri, params, additionalOptions.dataType);
};

const mergeRequestOptions = (params: RequestInit, extraParams: RequestInit) => {
  const { headers, ...nonHeaderParams } = extraParams;
  params.headers = params.headers ? params.headers : {};
  Object.assign(params.headers, headers);
  Object.assign(params, { ...nonHeaderParams });
};

// exported helper functions

export const getOrgID = (): string => {
  return window.sessionjs.getuserInfo()?.account_id;
};

export const getUserInfo = () => {
  const jwtData = readAndDecodeTokenFromCookie();
  const userData = {
    accountId: jwtData?.account_id,
    accountNumber: jwtData?.account_number,
    userId: jwtData?.user_id,
    isInternal:
      jwtData?.realm_access?.roles?.filter((item) => item === 'redhat:employees').length === 1,
  };
  return userData;
};

export const getAuthToken = () => {
  const cookie = window?.sessionjs?.token;
  return cookie ? cookie : null;
};

export const addQueryParamsToUri = (uri, params, includeEmptyString = false) => {
  params &&
    Object.keys(params).forEach((k) => {
      // add false and 0 to query params
      if (
        params[k] ||
        params[k] === false ||
        params[k] === 0 ||
        (includeEmptyString && params[k] === '')
      ) {
        uri.addQueryParam(k, params[k]);
      }
    });
};

export const getUri = <T>(
  uri,
  extraParams?: RequestInit,
  additionalOptions = defaultAdditionalOptions
): Promise<T> => {
  const params: RequestInit = {
    method: 'GET',
  };
  mergeRequestOptions(params, baseParams);
  extraParams && mergeRequestOptions(params, extraParams);
  return callFetchAndHandleJwt(uri, params, additionalOptions);
};

export const getUriWithBody = <T>(
  uri,
  body?: Type,
  extraParams?: RequestInit,
  additionalOptions = defaultAdditionalOptions
): Promise<T> => {
  const params: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  mergeRequestOptions(params, baseParams);
  extraParams && mergeRequestOptions(params, extraParams);
  return callFetchAndHandleJwt(uri, params, additionalOptions);
};

export const postUri = <T>(
  uri,
  body?: Type,
  extraParams?: RequestInit,
  additionalOptions = defaultAdditionalOptions
): Promise<T> => {
  const params: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  };
  mergeRequestOptions(params, baseParams);
  extraParams && mergeRequestOptions(params, extraParams);
  return callFetchAndHandleJwt(uri, params, additionalOptions);
};

export const postFormUri = <T>(
  uri: string,
  formData: FormData,
  extraParams?: RequestInit,
  additionalOptions = defaultAdditionalOptions
): Promise<T> => {
  const params: RequestInit = {
    method: 'POST',
    headers: {
      // Default enctype of html form element
      // All characters are encoded before sent (spaces are converted to "+" symbols, and special characters are converted to ASCII HEX values)
      // The value enctype="multipart/form-data" should be used in combination with the INPUT element, type="file".
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formData,
  };
  mergeRequestOptions(params, baseParams);
  extraParams && mergeRequestOptions(params, extraParams);
  return callFetchAndHandleJwt(uri, params, additionalOptions);
};

export const putUri = <T>(
  uri,
  body: Type,
  extraParams?: RequestInit,
  additionalOptions = defaultAdditionalOptions
): Promise<T> => {
  const params: RequestInit = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  mergeRequestOptions(params, baseParams);
  extraParams && mergeRequestOptions(params, extraParams);
  return callFetchAndHandleJwt(uri, params, additionalOptions);
};

export const patchUri = <T>(
  uri,
  body,
  extraParams?: RequestInit,
  additionalOptions = defaultAdditionalOptions
): Promise<T> => {
  const params: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  mergeRequestOptions(params, baseParams);
  extraParams && mergeRequestOptions(params, extraParams);
  return callFetchAndHandleJwt(uri, params, additionalOptions);
};

export const deleteUri = <T>(
  uri,
  extraParams?: RequestInit,
  additionalOptions = defaultAdditionalOptions
): Promise<T> => {
  const params: RequestInit = {
    method: 'DELETE',
  };
  mergeRequestOptions(params, baseParams);
  extraParams && mergeRequestOptions(params, extraParams);
  return callFetchAndHandleJwt(uri, params, additionalOptions);
};

export const deleteUriWithBody = <T>(
  uri,
  body,
  extraParams?: RequestInit,
  additionalOptions = defaultAdditionalOptions
): Promise<T> => {
  const params: RequestInit = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  mergeRequestOptions(params, baseParams);
  extraParams && mergeRequestOptions(params, extraParams);
  return callFetchAndHandleJwt(uri, params, additionalOptions);
};

/***
 * Returns mongodb id from the url
 */
export const getMongoId = () => {
  if (Env.isConnectPortal()) {
    return new URL(window.location.href).pathname.split('/')[2];
  } else {
    return Cookies.get(MONGOID_COOKIE);
  }
};

// TODO: Enable project id once app is close to completion
// Returns ProjectID from cookie object
// export const getProjectID = () => {

//   return Cookies.get(PROJECTID_COOKIE);
// }

export const isMocked = () => {
  return !!new Uri(window.location.href).getQueryParamValue('mock-data');
};
