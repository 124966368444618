import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Flex,
    FlexItem,
    StackItem,
} from '@patternfly/react-core';
import WebsiteImage from '../../../../assets/webiste.png';
import Code from '../../../../assets/code.svg';
import './CertifySoftwareCard.scss';
import Env from '../../../../utils/env';
import { DOCUMENTATION_Link } from '../../../../constants/DashBoardUrls'

const CertifySoftwareCard: React.FC = () => {
        return (
            <StackItem span={12}>
                <Card className="certify-card">
                    <Flex alignItems={{ default: 'alignItemsCenter' }} flexWrap={{ default: 'nowrap' }}>
                        <FlexItem>
                            <img className="certify-card__img" src={WebsiteImage} alt={'website-image'}
                                 aria-hidden={true} />
                        </FlexItem>
                        <FlexItem>
                            <Flex justifyContent={{ default: 'justifyContentSpaceBetween' }}
                                  alignItems={{ default: 'alignItemsCenter' }}>
                                <FlexItem>
                                    <CardTitle className="certify-card__title" component={'h2'} size={20}>Get
                                        started with your software products and
                                        listings
                                    </CardTitle>
                                </FlexItem>
                                <FlexItem>
                                    <img className="certify-card__title-icon" src={Code} alt={'code-icon'}
                                         aria-hidden={true} />
                                </FlexItem>
                            </Flex>
                            <CardBody>
                                Certify your product on Red Hat technology. After certification, publish
                                and advertise your product or service on the Red Hat Ecosystem
                                Catalog.
                            </CardBody>
                            <CardFooter>
                                <Flex>
                                    <FlexItem>
                                        <Button component="a"
                                                href={`${Env.ConnectPortalUrls[Env.getEnvName()]}manage/products`}
                                                variant={'primary'}>
                                            Create a product
                                        </Button>
                                    </FlexItem>
                                    <FlexItem>
                                        <Button component="a"
                                                href={DOCUMENTATION_Link}
                                                variant={'secondary'}>
                                            Read documentation
                                        </Button>
                                    </FlexItem>
                                </Flex>
                            </CardFooter>
                        </FlexItem>
                    </Flex>
                </Card>
            </StackItem>
        );
}

export default CertifySoftwareCard;
