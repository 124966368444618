import {
    GridItem,
    Progress,
    ProgressMeasureLocation,
    ProgressSize,
    Split,
    SplitItem,Text,TextContent,
} from '@patternfly/react-core';
import React from 'react';

const ProductScore=({scoreStatus, graphValues}: {scoreStatus: string, graphValues: {
        value: number,
        color: string,
        minWidth?: string
    }[]} )=>{
    return (
        <>
            <GridItem>
                <Split hasGutter={false}>
                    {graphValues?.map((graph, index) => (
                        <SplitItem key={`graph-${index}`}  isFilled={true}>
                            <Progress aria-label={`progress bar ${index}`} className="header-product-score__graph" style={{
                            '--pf-v5-c-progress__bar--before--BackgroundColor': graph?.color
                        } as React.CSSProperties} value={graph?.value || 0} size={ProgressSize.sm} measureLocation={ProgressMeasureLocation.none} />
                        </SplitItem>
                    ))}
                </Split>
            </GridItem>
            <TextContent className={'products__card__footer__product-status'}>
                <Text component={'p'}>
                    Product Score:{" "}<b>{scoreStatus}</b>
                </Text>
            </TextContent>
        </>
    )
}

export default ProductScore;
