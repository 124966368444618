import {
    Card,
    CardBody,
    CardFooter,
    Flex,
    FlexItem,
    Grid,
    GridItem,
    Text,
    TextContent,
    TextVariants,Truncate,
} from '@patternfly/react-core';
import {
    IActiveProductDetails,
    IProductListDetails,
    listingTypeOptions,
} from '../../../../constants/products';
import {
    getTypeNameWithIcon,
} from '../../../../utils/productListUtils';
import "./ProductCard.scss";
import ProductScore from './ProductScore'
import { useQueryClient } from 'react-query'
import Env from '../../../../utils/env'
import { useProductScoreMetadata } from '../../../../hooks/useProductScoreMetadata'

const PublishedProductCard = () => {
    const publishedProducts = useQueryClient().getQueryData<IActiveProductDetails>('productList')?.data?.publishedProducts;
    return (
        <div className={"products"}>
            <TextContent>
                <Text className={"products__heading"} component={TextVariants.h3}>Recently published products</Text>
            </TextContent>
            <Grid hasGutter>
                {publishedProducts?.data?.map((publishedProduct: IProductListDetails) => {
                    const typeName = listingTypeOptions.find(option => publishedProduct.type && option.value === publishedProduct.type)?.header || '';
                    const publishedDate = new Date(publishedProduct?.published_history?.history?.slice(-1)?.[0]?.date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })
                    const {graphValues, scoreStatus} = useProductScoreMetadata(publishedProduct?._id);
                    return (
                        <GridItem span={4} key={publishedProduct?._id}>
                            <Card className={'products__card'} component="a" href={`${Env.ConnectPortalUrls[Env.getEnvName()]}manage/products/${publishedProduct?._id}`}
                                  aria-label={`Published Product details card. Name: ${publishedProduct?.name}, Type: ${typeName}, Published date: ${publishedDate}, and Product Score status: ${scoreStatus} `}
                                  isFullHeight>
                                <CardBody>
                                    <Flex alignItems={{ default: 'alignItemsFlexStart' }} justifyContent={{ default: 'justifyContentSpaceBetween'}}>
                                        <FlexItem>
                                            <TextContent
                                                className={"products__card__description"}
                                            >
                                                <Text className={"products__card__description__type"} component={'p'}>
                                                    {typeName}
                                                </Text>
                                                <Text aria-hidden={true} component={'h4'}>
                                                    <Truncate content={publishedProduct?.name}></Truncate>
                                                </Text>
                                                <Text className={"products__card__description__cert-info"} component={'p'}>
                                                    Published:{" "} {publishedDate}
                                                </Text>
                                            </TextContent>
                                        </FlexItem>
                                        <FlexItem>
                                            <div className={"products__card__type-icon"}>
                                                {getTypeNameWithIcon(publishedProduct?.type)}
                                            </div>
                                        </FlexItem>
                                    </Flex>
                                </CardBody>
                                <CardFooter className={'products__card__footer'}>
                                    <ProductScore graphValues={graphValues} scoreStatus={scoreStatus} />
                                </CardFooter>
                            </Card>
                        </GridItem>
                    )})}
            </Grid>
        </div>
    )
}

export default PublishedProductCard;
