import { FC, useEffect, useState } from 'react';
import './HWPartnerOnboarding.scss';
import {
    Bullseye,
    Button,
    EmptyState,
    EmptyStateActions,
    EmptyStateBody,
    EmptyStateIcon,
    Grid,
    GridItem,
    Page,
    PageSection,
    PageSectionVariants,
    ProgressStep,
    ProgressStepper,
    Spinner,
    Title,
} from '@patternfly/react-core';
import Step1AcceptTnC from './Step1AcceptTnC';
import { useQuery } from 'react-query';
import Step2GetPartnerSub from './Step2GetPartnerSub';
import Step3Finish from './Step3Finish';
import { IPartnerInfo } from '../../constants/apiconstants-pfs';
import {
    getAgreements,
    getLoggedinPartnersInfo,
    getPartnerTermsStatus,
    getSubUseCaseMetadata,
} from '../../api/pfs';
import { TNCAgreementColumn } from '../../interfaces/tnc-agreements';
import { ExclamationTriangleIcon } from '@patternfly/react-icons';
import Env from '../../utils/env';
import { IErrorType } from '../../constants/apiconstants-hydra';

const HWPartnerOnboarding: FC = () => {
    const { isFetching: loadingPartnerInfo, data: partnerInfo, error: partnerInfoError } = useQuery<
        IPartnerInfo,
        IErrorType
    >(['partnerInfo'], async ({ signal }) => {
        return await getLoggedinPartnersInfo(false, signal);
    });
    const {
        data: agreementsResponse,
        isLoading: isAgreementsLoading,
        error: agreementsError,
    } = useQuery<TNCAgreementColumn[]>(['agreements'], async ({ signal }) => {
        const terms = await getAgreements(signal);
        const data: TNCAgreementColumn[] = [
            {
                name: 'Partner Terms and Conditions',
                status: terms?.partnerTermsAcknowledged,
                requiredFor: 'Red Hat partners',
            },
            {
                name: 'Red Hat Partner Code of Conduct',
                status: terms?.codeOfConductAcknowledged,
                requiredFor: 'Red Hat partners',
            },
            {
                name: 'Hardware Certification Program Appendix',
                status: terms?.hardwareAppendixAcknowledged,
                requiredFor: 'Hardware certification',
            },
        ];
        return data;
    });
    const { isLoading: isPartnerSubsTermsStatusLoading, error: partnerSubsTermsError } = useQuery(
        ['partnerSubsTermsStatus'],
        ({ signal }) => getPartnerTermsStatus(signal)
    );
    const { isLoading: useCaseMetaDataLoading, error: metaDataError } = useQuery(
        ['useCaseMetaData'],
        ({ signal }) => getSubUseCaseMetadata(signal)
    );
    const allTermsAccepted = Array.isArray(agreementsResponse)
        ? agreementsResponse.map((item) => item.status).every((accepted) => accepted)
        : false;
    const changePage = (page: 1 | 2 | 3) => {
        setStep(page);
    };
    let partnerSubProxyError = false;
    if (partnerInfo?.subscriptionStatus?.statusDetail === "ERROR") {
        partnerSubProxyError = true;
    }
    const subActive =
        partnerInfo?.subscriptionStatus?.active && !partnerInfo?.subscriptionStatus?.available;
    const [step, setStep] = useState<1 | 2 | 3>(1);
    useEffect(() => {
        if (subActive && allTermsAccepted) {
            setStep(3);
        } else if (allTermsAccepted) {
            setStep(2);
        }
    }, [subActive, allTermsAccepted]);
    if (
        (!partnerInfo && loadingPartnerInfo) ||
        isAgreementsLoading ||
        isPartnerSubsTermsStatusLoading ||
        useCaseMetaDataLoading
    ) {
        return (
            <Bullseye>
                <Spinner size={'xl'} />
            </Bullseye>
        );
    }
    if (agreementsError || partnerSubsTermsError || metaDataError || partnerInfoError || partnerSubProxyError) {
        return (
            <Page className='hardware-partner-onboarding'>
                <EmptyState>
                    <EmptyStateIcon
                        icon={ExclamationTriangleIcon}
                        color={'var(--pf-v5-global--warning-color--100)'}
                    />
                    <EmptyStateBody>Something went wrong</EmptyStateBody>
                    <EmptyStateActions>
                        <Button
                            variant='primary'
                            data-testid={'retry-btn'}
                            onClick={() => window.location.reload()}>
                            Retry
                        </Button>
                        <Button
                            variant='secondary'
                            style={{
                                marginLeft: '1em',
                            }}
                            component='a'
                            target='_blank'
                            rel='noreferrer'
                            data-testid={'tpdh-btn'}
                            href={`${
                                Env.ConnectPortalUrls[Env.getEnvName()]
                            }support/technology-partner/#/case/new`}>
                            Open support ticket
                        </Button>
                    </EmptyStateActions>
                </EmptyState>
            </Page>
        );
    }
    return (
        <Page className='hardware-partner-onboarding'>
            <PageSection variant={PageSectionVariants.darker}>
                <Title
                    style={{
                        marginLeft: '3em',
                    }}
                    headingLevel={'h1'}>
                    Red Hat partner onboarding
                </Title>
            </PageSection>
            <PageSection variant={PageSectionVariants.light} hasShadowBottom={true}>
                <Title
                    style={{
                        marginLeft: '8em',
                    }}
                    headingLevel={'h2'}>
                    Join the hardware certification program
                </Title>
            </PageSection>
            <PageSection
                className='hardware-partner-onboarding__main-page-section'
                variant={PageSectionVariants.light}>
                <Grid hasGutter={true}>
                    <GridItem span={3}>
                        <ProgressStepper
                            style={{
                                marginLeft: '8em',
                            }}
                            isVertical={true}>
                            <ProgressStep
                                variant={allTermsAccepted ? 'success' : 'info'}
                                id='terms-and-conditions'
                                isCurrent={step === 1}
                                titleId='terms-and-conditions'
                                aria-label='Terms and conditions'>
                                Terms and conditions
                            </ProgressStep>
                            <ProgressStep
                                variant={subActive ? 'success' : step === 2 ? 'info' : 'default'}
                                isCurrent={step === 2}
                                id='get-partner-subscription'
                                titleId='get-partner-subscription'
                                aria-label='Get partner Subscription'>
                                Get partner subscription
                            </ProgressStep>
                            <ProgressStep
                                variant={subActive ? 'success' : step === 3 ? 'info' : 'default'}
                                isCurrent={step === 3}
                                id='finish'
                                titleId='finish'
                                aria-label='Finish'>
                                Finish
                            </ProgressStep>
                        </ProgressStepper>
                    </GridItem>
                    <GridItem span={9}>
                        {step === 1 && (
                            <Step1AcceptTnC
                                allTermsAccepted={allTermsAccepted}
                                changePage={changePage}
                            />
                        )}
                        {step === 2 && <Step2GetPartnerSub changePage={changePage} />}
                        {step === 3 && <Step3Finish />}
                    </GridItem>
                </Grid>
            </PageSection>
        </Page>
    );
};

export default HWPartnerOnboarding;
