import Env from '../utils/env';
import { getUri } from '../utils/fetch';
import {
    IAccountInfo,
    IContactListResponse,
    IPartnerTraining,
} from '../constants/apiconstants-hydra';
import Uri from 'jsuri';
import { IPageState } from '@rhc-shared-components/table-toolbar/dist/models/GeneralModels';
export const fetchCompanyProfileDetails = (): Promise<IAccountInfo> => {
    const uri = Env.accountsAPIUrl?.clone().setPath(`${Env.accountsAPIPrefix}/accounts`);
    uri?.addQueryParam('firstResultIndex', 0);
    uri?.addQueryParam('maxResults', 100);
    return getUri<IAccountInfo>(uri);
};

export const getAccountContacts = (
    { page, perPage }: IPageState,
    extraParams?: {includeOrgAdmins?: boolean; includeDeactivatedMembers?: boolean , sortParams?: { sortBy: string; direction: string; }},
    abortSignal?: AbortSignal
): Promise<IContactListResponse> => {
    const additionalParams: RequestInit = {};
    const uri = new Uri(`${Env.apiURLS}${Env.hydraAPIPrefix}/companies/current/contacts`);
    uri.addQueryParam('limit', perPage);
    uri.addQueryParam('offset', (page - 1) * perPage);
    uri.addQueryParam('match', "all");
    if(extraParams?.includeOrgAdmins !== undefined) {
        uri.addQueryParam('isOrgAdmin', extraParams?.includeOrgAdmins);
    }
    if (extraParams?.includeDeactivatedMembers !== undefined) {
        uri.addQueryParam('isDeactivated', extraParams?.includeDeactivatedMembers);
    }
    if(extraParams?.sortParams) {
        uri.addQueryParam('sortdirection', extraParams?.sortParams?.direction.toUpperCase());
        uri.addQueryParam('sortby', extraParams?.sortParams?.sortBy);
    }
    if (abortSignal) {
        Object.assign(additionalParams, { signal: abortSignal });
    }
    return getUri(uri, additionalParams);
};

export const getPartnerTrainingDetails = (
    limit:number,
    extraParams?: {fromDate?: string, toDate?: string, offset?: number}
): Promise<IPartnerTraining>=> {
    const uri = new Uri(`${Env.apiURLS}${Env.hydraAPIPrefix}/user/training/report`);
    uri.addQueryParam('limit', limit);
    if(extraParams?.fromDate !== undefined) {
        uri.addQueryParam('fromDate', extraParams?.fromDate);
    }
    if (extraParams?.toDate !== undefined) {
        uri.addQueryParam('toDate', extraParams?.toDate);
    }
    if(extraParams?.offset) {
        uri.addQueryParam('offset', extraParams?.offset);
    }
    return getUri(uri);
};
