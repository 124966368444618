import Env from '../utils/env';
import { getUri, postUri } from '../utils/fetch';
import { ILoginId,IPartnerInfo,IPartnershipStatus } from '../constants/apiconstants-pfs';
import Uri from 'jsuri';
import { IPartnerSubTermStatus, IRequestSubscription, IUseCaseMetaData } from '../interfaces/pfs';
import { ITermsAndCondition } from '../interfaces/tnc-agreements';
import { ProductScoreResponse } from '../constants/products'

// API to check if the user trying to login is a Red Hat partner
export const checkRedHatConnectPartner = (loginId: ILoginId) => {
    const uri = Env.pfsAPIUrl.clone().setPath('/pfs/ps/partners/check');
    return postUri(uri, loginId);
};

// Get logged in Partners info
export const getLoggedinPartnersInfo = (
    includeTechPartnerOnBoarding?: boolean,
    abortSignal?: AbortSignal
): Promise<IPartnerInfo> => {
    const additionalParams: RequestInit = {};
    const uri = new Uri(`${Env.apiURLS}/pfs/ps/partners/current`);
    if (includeTechPartnerOnBoarding) {
        uri.addQueryParam('includeTechPartnerOnBoarding', includeTechPartnerOnBoarding);
    }
    if (abortSignal) {
        Object.assign(additionalParams, { signal: abortSignal });
    }
    return getUri(uri, additionalParams);
};


// Get logged in Partners onboarding status
export const getPartnershipStatus = (
    abortSignal?: AbortSignal
): Promise<IPartnershipStatus> => {
    const additionalParams: RequestInit = {};
    const uri = new Uri(`${Env.apiURLS}/pfs/ps/partners/check`);
    if (abortSignal) {
        Object.assign(additionalParams, { signal: abortSignal });
    }
    return getUri(uri, additionalParams);
};

// Check Partner subscription Terms and Conditions
export const getPartnerTermsStatus = (
    abortSignal?: AbortSignal
): Promise<IPartnerSubTermStatus> => {
    const additionalParams: RequestInit = {};
    const uri = `${Env.apiURLS}/pfs/ps/partners/current/terms-status`;
    if (abortSignal) {
        Object.assign(additionalParams, { signal: abortSignal });
    }
    return getUri(uri, additionalParams);
};

// Get use case values for hardware partner subs
export const getSubUseCaseMetadata = (abortSignal?: AbortSignal): Promise<IUseCaseMetaData> => {
    const additionalParams: RequestInit = {};
    const uri = `${Env.apiURLS}/pfs/ps/metadata/partner_subscription_use_case`;
    if (abortSignal) {
        Object.assign(additionalParams, { signal: abortSignal });
    }
    return getUri(uri, additionalParams);
};

// Create a new Subscription for logged in Partner
export const createSubscription = ({ useCases, useCaseDescription }: IRequestSubscription) => {
    const uri = `${Env.apiURLS}/pfs/ps/partners/current/subscription-requests`;
    return postUri(uri, {
        useCases,
        useCaseDescription,
    });
};

export const getAgreements = (abortSignal?: AbortSignal): Promise<ITermsAndCondition> => {
    const additionalParams: RequestInit = {};
    const uri = Env.pfsAPIUrl
        .clone()
        .setPath(`${Env.pfsPSPrefix}/partners/current/terms-status/ackterms`);
    if (abortSignal) {
        Object.assign(additionalParams, { signal: abortSignal });
    }
    return getUri(uri, additionalParams);
};

export const getProductScoreStatus = (id: string, abortSignal?: AbortSignal): Promise<ProductScoreResponse> => {
    const uri = `${Env.apiURLS}/pfs/cs/product-listings/id/${id}/score`;
    const additionalParams: RequestInit = {};
    if (abortSignal) {
        Object.assign(additionalParams, {'signal': abortSignal})
    }
    return getUri(uri, additionalParams);
};
