import { FC, MutableRefObject, ReactElement, useRef, useState } from 'react';
import {
    ActionGroup,
    Button,
    EmptyState,
    Form,
    Text,
    TextContent,
    TextVariants,
    EmptyStateBody,
    EmptyStateIcon,
    HelperText,
    HelperTextItem,
    EmptyStateActions,
    SelectOptionProps,
    EmptyStateFooter
} from '@patternfly/react-core';
import { IPartnerInfo } from '../../constants/apiconstants-pfs';
import { useMutation, useQueryClient } from 'react-query';
import { CheckCircleIcon, ExclamationTriangleIcon } from '@patternfly/react-icons';
import Env from '../../utils/env';
import {
    IPartnerSubTermStatus,
    IRequestSubscription,
    IUseCaseMetaData,
} from '../../interfaces/pfs';
import { Formik, FormikProps } from 'formik';
import {
    FormMultiSelectInput,
} from '@rhc-shared-components/form-multi-select-component';
import { FormTextArea } from '@rhc-shared-components/form-text-area';
import * as Yup from 'yup';
import { createSubscription } from '../../api/pfs';
import { SuccessPopup, useAlertContext, ErrorPopup } from '@rhc-shared-components/alert-component';
import { FormContainer } from '@rhc-shared-components/form'

enum SubscriptionRequest {
    useCases = 'useCases',
    useCaseDescription = 'useCaseDescription',
}
const CommonHeader: FC<{
    children: ReactElement | ReactElement[] | string;
    actionButtons?: ReactElement;
}> = ({ children, actionButtons }) => (
    <div className='wizard-main'>
        <TextContent>
            <Text component={TextVariants.h2}>
                Congratulations, you are now a Hardware partner!
            </Text>
            <Text className='page-description'>
                However, before you start certifying products, you will first need to request a free
                partner subscription
            </Text>
        </TextContent>
        {children}
        {actionButtons && actionButtons}
    </div>
);

const ErrorPage: FC<{
    message: string;
}> = ({ message }) => (
    <EmptyState className='empty-state'>
        <EmptyStateIcon
            icon={ExclamationTriangleIcon}
            color={'var(--pf-v5-global--warning-color--100)'}
        />
        <EmptyStateBody>{message}</EmptyStateBody>
        <EmptyStateFooter>
            <EmptyStateActions>
                <Button
                    variant="primary"
                    component="a"
                    target="_blank"
                    rel="noreferrer"
                    data-testid={'tpdh-btn'}
                    href={`${
                        Env.ConnectPortalUrls[Env.getEnvName()]
                    }support/technology-partner/#/case/new`}>
                    Open support ticket
                </Button>
                <Button
                    style={{
                        marginLeft: '1em',
                    }}
                    variant="secondary"
                    component="a"
                    rel="noreferrer"
                    data-testid={'back-to-portal-btn'}
                    href={`${Env.ConnectPortalUrls[Env.getEnvName()]}`}>
                    Return to partner portal
                </Button>
            </EmptyStateActions>
        </EmptyStateFooter>
    </EmptyState>
);
interface SubFormValues {
    [SubscriptionRequest.useCases]: any[];
    [SubscriptionRequest.useCaseDescription]: string;
}
const STORAGE_KEY = 'hwPartnerSubsFormValues';
const Step2GetPartnerSubs: FC<{
    changePage: (page: 1 | 2 | 3) => void;
}> = ({ changePage }) => {
    const queryClient = useQueryClient();
    const partnerInfo = queryClient.getQueryState(['partnerInfo'])?.data as IPartnerInfo;
    const partnerInfoIsLoading = queryClient.getQueryState(['partnerInfo'])?.isFetching;
    const apiError =
        queryClient.getQueryState(['partnerInfo'])?.error ||
        queryClient.getQueryState(['partnerSubsTermsStatus'])?.error ||
        queryClient.getQueryState(['useCaseMetaData'])?.error;
    const [subscriptionAPIError, setSubscriptionAPIError] = useState<string | null>(null);
    const partnerSubTermsStatus = queryClient.getQueryState(['partnerSubsTermsStatus'])
        ?.data as IPartnerSubTermStatus;
    const useCaseMetaData = queryClient.getQueryState(['useCaseMetaData'])
        ?.data as IUseCaseMetaData;
    const subscriptionUseCaseItems: SelectOptionProps[] =
        useCaseMetaData !== undefined
            ? useCaseMetaData?.values.map((item) => ({ value: item.label,children: item.label }))
            : [];
    const formRef = useRef<FormikProps<SubFormValues>>(null);
    const { setAlertState } = useAlertContext();
    const createSubscriptionMutation = useMutation(createSubscription, {
        onSuccess: async (data: any) => {
            localStorage.removeItem(STORAGE_KEY);
            if (data?.activationStatus?.statusDetail === 'SUCCESS') {
                SuccessPopup(setAlertState, 'Partner subscription successfully requested');
                await queryClient.invalidateQueries(['partnerInfo']);
            } else if (
                [
                    'RESTRICTED',
                    'ERROR_OFAC',
                    'DOMAIN_BLOCKING',
                    'ERROR_T5',
                    'EMBARGOED_COUNTRY_BLOCK',
                ].includes(data?.activationStatus?.statusDetail)
            ) {
                setSubscriptionAPIError(
                    'In compliance with U.S. trade control regulations, your account has been restricted. Please read'
                );
            } else {
                setSubscriptionAPIError(
                    'There is an issue with the Red Hat Partner Subscription service, please retry your subscription request. For more information on possible service outages check'
                );
            }
        },
        onError: (error: any) => {
            localStorage.removeItem(STORAGE_KEY);
            if (error.statusCode === 403) {
                // @ts-ignore
                // prettier-ignore
                ErrorPopup(setAlertState, 'You are un-authorised to perform this action');
            } else {
                // @ts-ignore-next-line
                // prettier-ignore
                ErrorPopup(setAlertState,'Something went wrong while requesting partner subscription');
            }
            formRef.current?.setSubmitting(false);
        },
    });
    // Additional approval is required or API errored
    if (
        partnerInfo.accountEligibilityStatus.domainBlocked ||
        partnerInfo.accountMembershipDetail.ccspPartner ||
        apiError ||
        subscriptionAPIError
    ) {
        return (
            <CommonHeader
                actionButtons={
                    <ActionGroup className='button-group'>
                        <Button
                            onClick={() => {
                                changePage(1);
                            }}
                            variant='secondary'>
                            Back
                        </Button>
                    </ActionGroup>
                }>
                <ErrorPage
                    message={
                        subscriptionAPIError
                            ? subscriptionAPIError
                            : 'We are unable to provide a partner subscription for your company at this' +
                              ' time. If you belive this is an error, please open a support ticket. You ' +
                              'will not be able to use the Red Hat Certification Tool without ' +
                              'additional action'
                    }
                />
            </CommonHeader>
        );
    }
    // Already has an active subscription
    if (partnerInfo?.subscriptionStatus?.active && !partnerInfo?.subscriptionStatus?.available) {
        return (
            <CommonHeader
                actionButtons={
                    <Form>
                        <ActionGroup className='button-group'>
                            <Button
                                onClick={() => {
                                    changePage(1);
                                }}
                                variant='secondary'>
                                Back
                            </Button>
                            <Button
                                onClick={() => {
                                    changePage(3);
                                }}
                                variant='primary'>
                                Next
                            </Button>
                        </ActionGroup>
                    </Form>
                }>
                <EmptyState className='empty-state'>
                    <EmptyStateIcon
                        icon={CheckCircleIcon}
                        color={'var(--pf-v5-global--success-color--100)'}
                    />
                    <EmptyStateBody>
                        Your company already has a partner subscription. Click &lsquo;Next&lsquo; to
                        continue.
                    </EmptyStateBody>
                </EmptyState>
            </CommonHeader>
        );
    }
    // Partner employee is not an org admin
    if (!partnerInfo?.orgAdmin) {
        return (
            <CommonHeader
                actionButtons={
                    <ActionGroup className='button-group'>
                        <Button
                            onClick={() => {
                                changePage(1);
                            }}
                            variant='secondary'>
                            Back
                        </Button>
                    </ActionGroup>
                }>
                <ErrorPage
                    message={
                        'We recommend you contact your company’s account Org. Admin to request ' +
                        'access. If you don’t know your company’s account Org. Admin, contact the Red Hat' +
                        ' Partner Acceleration Desk to see if they can forward the request for you.'
                    }
                />
            </CommonHeader>
        );
    }

    const getInitialValues = () => {
        let initialValues;
        const values = JSON.parse(localStorage.getItem(STORAGE_KEY) || 'null');
        if (values) {
            initialValues = values;
        } else {
            initialValues = {
                [SubscriptionRequest.useCases]: [],
                [SubscriptionRequest.useCaseDescription]: '',
            };
        }
        return initialValues;
    };
    const triggerValidation = (formRef: MutableRefObject<FormikProps<SubFormValues> | null>) => {
        formRef.current?.validateForm().then((errors) => {
            if (!(Object.keys(errors).length === 0)) {
                // Validation errors exist, set all fields as touched
                formRef.current?.setTouched(
                    Object.keys(errors).reduce((acc: any, errorKey) => {
                        acc[errorKey] = true;
                        return acc;
                    }, {})
                );
            }
        });
    };
    // Can request subscription
    return (
        <CommonHeader
            actionButtons={
                <Form>
                    <ActionGroup className='button-group'>
                        <Button
                            isDisabled={createSubscriptionMutation.isLoading}
                            onClick={() => {
                                changePage(1);
                            }}
                            variant='secondary'>
                            Back
                        </Button>
                        {partnerSubTermsStatus.partnerSubscriptionTermsAcknowledged &&
                        partnerSubTermsStatus.partnerSubscriptionTermsAcknowledged ? (
                            <Button
                                isDisabled={createSubscriptionMutation.isLoading}
                                isLoading={createSubscriptionMutation.isLoading}
                                onClick={() => {
                                    if (formRef.current?.isValid) {
                                        const values = formRef?.current?.values;
                                        values[SubscriptionRequest.useCases] =
                                            useCaseMetaData.values
                                                .filter((element: { label: any }) =>
                                                    values[SubscriptionRequest.useCases].includes(
                                                        element.label
                                                    )
                                                )
                                                .map((obj: { code: any; label: any }) => {
                                                    return {
                                                        code: obj.code,
                                                        label: obj.label,
                                                    };
                                                });
                                        values[SubscriptionRequest.useCaseDescription] = values[
                                            SubscriptionRequest.useCases
                                        ].find((elm: any) => elm.label === 'Other')
                                            ? values[SubscriptionRequest.useCaseDescription]
                                            : '';
                                        formRef.current?.setSubmitting(true);
                                        createSubscriptionMutation.mutate(
                                            values as IRequestSubscription
                                        );
                                    } else {
                                        triggerValidation(formRef);
                                    }
                                }}
                                variant='primary'>
                                Request partner subscription
                            </Button>
                        ) : (
                            <Button
                                onClick={() => {
                                    if (formRef.current?.dirty && formRef.current?.isValid) {
                                        localStorage.setItem(
                                            STORAGE_KEY,
                                            JSON.stringify(formRef?.current?.values),
                                        );
                                        const uGCPortalUrls = Env.UGCPortals[Env.getEnvName()];
                                        const redirectUrl: string = encodeURI(window.location.href);
                                        window.location.href = `${uGCPortalUrls}wapps/tnc/ackrequired?site=partnerConnect&event=partnersubs,enterpriseAgreement&redirect=${redirectUrl}&cancelRedirect=${redirectUrl}`;
                                    } else {
                                        triggerValidation(formRef);
                                    }
                                }}
                                variant='primary'>
                                Request partner subscription
                            </Button>
                        )}
                    </ActionGroup>
                </Form>
            }>
            <span>
                <HelperText className='helper-text'>
                    <HelperTextItem variant='indeterminate'>
                        In order to improve our products and understand how they&apos;re used, we
                        need a few details about the way it will be used. (Don&apos;t worry, we
                        won&apos;t spam you with emails)
                    </HelperTextItem>
                </HelperText>
                <Formik
                    validateOnMount={!!localStorage.getItem(STORAGE_KEY)}
                    validateOnChange={true}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    innerRef={formRef}
                    validationSchema={Yup.object().shape({
                        [SubscriptionRequest.useCases]: Yup.array().min(
                            1,
                            'At least one selection is required'
                        ),
                        [SubscriptionRequest.useCaseDescription]: Yup.string().when(
                            [SubscriptionRequest.useCases],
                            (elm: any, schema: any) => {
                                return elm.includes('Other')
                                    ? Yup.string().required('This is a required field')
                                    : schema;
                            }
                        ),
                    })}
                    initialValues={getInitialValues()}
                    onSubmit={() => {}}>
                    {({ values }) => (
                        <FormContainer gridSpan={7}>
                            <FormMultiSelectInput
                                data-testid='usecase-dropdown'
                                name={SubscriptionRequest.useCases}
                                maxHeight={200}
                                label='How will you use this subscription?'
                                placeholder='Select'
                                selectMenuOptions={subscriptionUseCaseItems}
                                isDisabled={partnerInfoIsLoading}
                                menuAppendTo={'parent'}
                                isRequired
                            />
                            {!!values[SubscriptionRequest.useCases]?.find(
                                (elm: string) => elm === 'Other'
                            ) && (
                                <FormTextArea
                                    data-testid='useCase-description-textArea'
                                    name={SubscriptionRequest.useCaseDescription}
                                    placeholder={'We will use this subscription to...'}
                                    isRequired
                                    isDisabled={partnerInfoIsLoading}
                                    label='Additional details about your use case'
                                />
                            )}
                        </FormContainer>
                    )}
                </Formik>
            </span>
        </CommonHeader>
    );
};

export default Step2GetPartnerSubs;
