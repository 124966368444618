import { FC } from 'react';
import {
    Button,
    Stack,
    StackItem
} from '@patternfly/react-core';
import HardwareIcon from '../../../assets/Icon-Red_Hat-Circuit-A-Red-RGB.png';
import { DashboardCard } from '../shared/DashboardCard';
import Env from '../../../utils/env';
import HalfGridCardWrapper from "../shared/HalfGridCardWrapper"
import { IPartnershipStatus } from "../../../constants/apiconstants-pfs"
import { useQueryClient } from "react-query"

const HardwareCardBody: FC = () => (
    <Stack hasGutter>
        <StackItem>
            Access tools to certify your hardware systems, appliances and components with Red Hat platforms
        </StackItem>
        <StackItem>
            <Button
                variant='link'
                isInline
                component='a'
                href={`${Env.RHCERTURLs[Env.getEnvName()]}certifications#/?ecosystem=hardware`}>
                View certification cases
            </Button>
        </StackItem>
        <StackItem>
            <Button
                variant='link'
                isInline
                component='a'
                href={`${Env.RHCERTURLs[Env.getEnvName()]}?source=external`}>
                Create new certification case
            </Button>
        </StackItem>
    </Stack>
);

export const HardwareCard: FC = () => {
    const queryClient = useQueryClient();
    const partnershipStatus = queryClient.getQueryState(['partnershipStatus'])
        ?.data as IPartnershipStatus;
    if (partnershipStatus?.hardwarePartner) {
        return (<HalfGridCardWrapper>
            <DashboardCard
                icon={HardwareIcon}
                title='Hardware certification'
                openNewTab={false}
                link={null}
                description={null}
                label={null}
                cardBody={<HardwareCardBody/>}
                variant='inline'
            />
        </HalfGridCardWrapper>)
    }
    return null;
};
