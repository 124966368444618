import { FC } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Flex,
    FlexItem,
    Skeleton,
    Stack,
    StackItem,
    Text,
    TextContent,
    TextVariants
} from '@patternfly/react-core';
import { Table, TableText, Tbody, Td, Tr } from '@patternfly/react-table';
import { ChartPie } from '@patternfly/react-charts';
import { useQuery,useQueryClient } from 'react-query';
import { DashboardCard } from '../shared/DashboardCard';
import GraduationCap from '../../../assets/Icon-Red_Hat-Graduation_cap-A-Red-RGB.png';
import { getPartnerTrainingDetails } from '../../../api/hydra';
import { IPartnerTraining,IPartnerTrainingDetails } from '../../../constants/apiconstants-hydra';
import {
    ALL_COURSES_LINK,
    COURSE_LINK,
    ENROLLED_PARTNER_COURSES_LINK,
} from '../../../constants/DashBoardUrls';
import './PartnerTrainingCard.scss';
import HalfGridCardWrapper from "../shared/HalfGridCardWrapper"

const UnenrolledPartnersTrainingCardBody = () => {
    return (
        <Stack hasGutter>
            <StackItem>
                <TextContent>
                    <Text component={"p"}>
                        Our training offerings help you improve customer experiences,
                        deliver Red Hat related services, and increase your sales.
                    </Text>
                    <Text component={"p"}>
                        Get started with training and earn accreditations today.
                    </Text>
                </TextContent>
            </StackItem>
            <StackItem>
                <Button
                    variant={"primary"}
                    component="a"
                    href={ALL_COURSES_LINK}
                    target={"_blank"}
                >
                    Get started
                </Button>
            </StackItem>
        </Stack>
    );
};

const EnrolledPartnersTrainingCardBody = () => {
    const queryClient = useQueryClient();
    const partnerTrainingDetails = queryClient.getQueryData<IPartnerTraining>(['partnerTrainingInfo']);
    return (
        <div className='enrolled-partner-training'>
            <TextContent>
                <Text component={TextVariants.h4}>Recent learning paths</Text>
            </TextContent>
            <Table className='enrolled-partner-training__table' variant={"compact"}>
                <Tbody>
                    {partnerTrainingDetails?.items?.map((trainingDetail:IPartnerTrainingDetails) => {
                        const completionPercentage=parseInt(trainingDetail.progress);
                        return (
                            <Tr key={trainingDetail.learningPathId}>
                                <Td width={80}>
                                    <TableText wrapModifier={'truncate'}>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={COURSE_LINK + trainingDetail.learningPathId}
                                        >
                                            {trainingDetail.learningPathName}
                                        </a>
                                    </TableText>
                                </Td>
                                <Td>
                                    <Flex
                                        flexWrap={{ default: 'nowrap' }}
                                        alignItems={{ default: 'alignItemsCenter' }}
                                        className='enrolled-partner-training__table__pie-chart-content'
                                    >
                                        <FlexItem
                                            className='enrolled-partner-training__table__pie-chart-content__percentage-text'
                                            spacer={{ default: 'spacerNone' }}
                                        >
                                            <TableText wrapModifier={'wrap'}>
                                                {completionPercentage}% Completed
                                            </TableText>

                                        </FlexItem>
                                        <FlexItem>
                                            <ChartPie
                                                data={[
                                                    {
                                                        x: 'completed',
                                                        y: completionPercentage,
                                                    },
                                                    {
                                                        x: 'Remaining',
                                                        y: 100 - completionPercentage,
                                                    },
                                                ]}
                                                height={30}
                                                radius={15}
                                                width={30}
                                                ariaTitle={'Pie chart displaying the progress of course completion.'}
                                            />
                                        </FlexItem>
                                    </Flex>
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </div>
    );
};

const PartnerTrainingCard: FC = () => {
    const partnerTrainingInfoLimit = 3;
    const { data: partnerTrainingInfo, isLoading} = useQuery(
        ['partnerTrainingInfo'],
        () => getPartnerTrainingDetails(partnerTrainingInfoLimit)
    );
    const hasPartnerEnrolledInTraining = (partnerTrainingInfo?.items?.length ?? 0) >0;
    if (isLoading) {
        return (
            <HalfGridCardWrapper>
                <Card isFullHeight>
                    <CardHeader>
                        <Flex direction={{ default: 'row' }}
                              alignItems={{ default: 'alignItemsCenter' }}>
                            <FlexItem spacer={{ default: 'spacerSm' }}>
                                <Skeleton height={`30px`} width={'200px'}></Skeleton>
                            </FlexItem>
                            <Skeleton height={`30px`} width={'40px'}></Skeleton>
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <Stack hasGutter>
                            <StackItem>
                                <Skeleton fontSize={'md'} />
                            </StackItem>
                            <StackItem>
                                <Skeleton fontSize={'4xl'} />
                            </StackItem>
                            <StackItem>
                                <Skeleton fontSize={'4xl'} />
                            </StackItem>
                            <StackItem>
                                <Skeleton fontSize={'4xl'} />
                            </StackItem>
                        </Stack>
                    </CardBody>
                    <CardFooter>
                        <Flex direction={{ default: 'row' }}
                              alignItems={{ default: 'alignItemsCenter' }}>
                            <FlexItem spacer={{ default: 'spacerSm' }}>
                                <Skeleton height={`30px`} width={'150px'}></Skeleton>
                            </FlexItem>
                            <Skeleton height={`30px`} width={'150px'}></Skeleton>
                        </Flex>
                    </CardFooter>
                </Card>
            </HalfGridCardWrapper>
        )
    }
    return (
       <HalfGridCardWrapper>
           <DashboardCard
               icon={GraduationCap}
               title={<a href={`https://training-lms.redhat.com/sso/saml/auth/rhopen?RelayState=clmsbrowsev2.prMain%253Fin_sessionId%253D%2524XLR8_SESSIONID%2524`}>Partner training</a>}
               openNewTab={false}
               link={[
                   {
                       link: ALL_COURSES_LINK,
                       label: "View all courses",
                       openNewTab: true,
                   },
                   ...(hasPartnerEnrolledInTraining
                       ? [
                           {
                               link: ENROLLED_PARTNER_COURSES_LINK,
                               label: "View my training",
                               openNewTab: true,
                           },
                       ]
                       : []),
               ]}
               description={"graduation cap"}
               label={"View all courses"}
               isSelectable={false}
               cardBody={hasPartnerEnrolledInTraining ? <EnrolledPartnersTrainingCardBody /> : <UnenrolledPartnersTrainingCardBody />}
               variant="inline"
               hasLabelIcon={false}
           />
       </HalfGridCardWrapper>
    );
};

export default PartnerTrainingCard;
