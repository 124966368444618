import React, { useState } from 'react';
import { TableContext } from './TableContext';
import { IActionsResolver, IAreActionsDisabled } from '@patternfly/react-table';
import { IColumn } from './PFTableV2';

export interface TableProps {
  columns: IColumn[];
  rows: any;
  totalItems: number;
  children: any;
  actionResolver: IActionsResolver;
  areActionsDisabled: IAreActionsDisabled;
  searchFunction?: (query: string, filterType?: string, filterValue?: string|number, setSelectedPage?: React.Dispatch<React.SetStateAction<number>>) => any;
  selectItemsFunction?: (filter: string, currentPage: number, setSelectedPage: React.Dispatch<React.SetStateAction<number>>, perPage: number) => any;
}

const Table = (props: any) => {
  const [currentPage, setSelectedPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [selectionFilterValue, setSelectionFilter] = useState<string>(props.selectionFilterValue || '');
  const [searchValue, setSearchValue] = useState<string>('');

  return (
    <TableContext.Provider
      value={{
        columns: props.columns,
        rows: props.rows,
        totalItems: props.totalItems || 0,
        actionResolver: props.actionResolver,
        areActionsDisabled: props.areActionsDisabled,
        searchFunction: props.searchFunction,
        selectItemsFunction: props.selectItemsFunction,
        perPage,
        searchValue,
        currentPage,
        selectionFilterValue,
        setSelectedPage,
        setPerPage,
        setSelectionFilter,
        setSearchValue,
        disableBuiltInPagination: props.disableBuiltInPagination
      }}
    >
      {props.children}
    </TableContext.Provider>
  );
};

export default Table;

