import {
    Card,
    CardBody,
    CardFooter,
    Flex,
    FlexItem,
    Grid,
    GridItem,
    Progress,
    ProgressMeasureLocation,
    Text,
    TextContent,
    TextVariants,
    Truncate,
} from '@patternfly/react-core';
import {
    listingTypeOptions,
    IProductListDetails,IActiveProductDetails,
} from '../../../../constants/products';
import {
    calculateLocalProductScore,
    getProductPublishStatusText,
    getTypeNameWithIcon,
} from '../../../../utils/productListUtils';
import "./ProductCard.scss";
import { AddProductInfoCard } from './General/AddProductInfoCard';
import { useQueryClient } from 'react-query'
import Env from '../../../../utils/env'

const UnfinishedProductCard = () => {
    const unfinishedProducts = useQueryClient().getQueryData<IActiveProductDetails>('productList')?.data?.unfinishedProducts
    return (
        <div className={"products"}>
            <TextContent>
                <Text className={"products__heading"} component={TextVariants.h3}>Finish your product listing</Text>
            </TextContent>
            <Grid hasGutter>
                {unfinishedProducts?.data?.map((unpublishedProduct: IProductListDetails) => {
                    const typeName = listingTypeOptions.find(option => unpublishedProduct.type && option.value === unpublishedProduct.type)?.header || '';
                    const {productScorePercentage, scoreList, scoreCert} = calculateLocalProductScore(unpublishedProduct)
                    const statusInfo = getProductPublishStatusText( scoreCert, scoreList )
                    return (
                    <GridItem lg={4} md={4} sm={12} key={unpublishedProduct?._id}>
                        <Card className={'products__card'}  component="a" href={`${Env.ConnectPortalUrls[Env.getEnvName()]}manage/products/${unpublishedProduct?._id}`}
                              aria-label={`Unfinished Product details card. Name: ${unpublishedProduct?.name}, Type: ${typeName}, Status info: ${statusInfo}, and Completion: ${productScorePercentage}%.`}
                              isFullHeight>
                            <CardBody>
                                <Flex alignItems={{ default: 'alignItemsFlexStart' }} justifyContent={{ default: 'justifyContentSpaceBetween' }}>
                                    <FlexItem>
                                        <TextContent
                                            className={"products__card__description"}
                                        >
                                            <Text className={"products__card__description__type"} component={'p'}>
                                                {typeName}
                                            </Text>
                                            <Text aria-hidden={true} component={'h4'}>
                                                <Truncate content={unpublishedProduct?.name}></Truncate>
                                            </Text>
                                            <Text className={"products__card__description__cert-info"} component={'p'}>
                                                {statusInfo}
                                            </Text>
                                        </TextContent>
                                    </FlexItem>
                                    <FlexItem>
                                        <div className={"products__card__type-icon"}>
                                            {getTypeNameWithIcon(unpublishedProduct.type)}
                                        </div>
                                    </FlexItem>
                                </Flex>
                            </CardBody>
                            <CardFooter>
                                <Progress aria-label={`${productScorePercentage} complete`} aria-hidden={true} value={productScorePercentage}
                                          measureLocation={ProgressMeasureLocation.none} />
                                <TextContent>
                                    <Text component={'p'}>
                                        {productScorePercentage}% complete
                                    </Text>
                                </TextContent>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    )})}

                <>
                    {
                        unfinishedProducts?.data?.length === 1 && (
                            <GridItem span={8}>
                                <AddProductInfoCard heading={"Grow your product listings"}/>
                            </GridItem>
                        )
                    }
                </>
                <>
                    {
                        unfinishedProducts?.data?.length === 0 && (
                            <GridItem span={8}>
                                <AddProductInfoCard heading={"There are no unpublished product listings"}/>
                            </GridItem>
                        )
                    }
                </>
            </Grid>
        </div>
    )
}

export default UnfinishedProductCard;
